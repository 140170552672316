import classNames from 'classnames';

export type Brackets = readonly [
  openingBracket: string,
  closingBracket: string
];

export namespace Brackets {
  export const className = classNames('object-explorer__bracket');
}
