import React from 'react';

import { $config } from '@client/core/atoms/config.js';
import * as RelevantDigital from '@client/core/relevantDigital/index.js';
import { debugLog, DeviceType } from '@schibsted-nmp/advertising-shared';

import { ObjectExplorer } from '../ObjectExplorer/index.js';

export function RelevantTab() {
  const [filteredPlacements, setFilteredPlacements] =
    React.useState<ReadonlyArray<RelevantDigital.Api.Config.AdUnit> | null>(
      null
    );

  const [generalConfig, setGeneralConfig] =
    React.useState<RelevantTab.GeneralConfig | null>(null);

  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (typeof window.relevantDigital === 'undefined') {
      setError('RelevantDigital is not defined.');
    }

    type WebDeviceType = Exclude<
      DeviceType,
      DeviceType.Android | DeviceType.Ios
    >;

    const deviceTypeMapping: Record<string, 'wde' | 'wph'> &
      Record<WebDeviceType, 'wde' | 'wph'> = {
      [DeviceType.Desktop]: 'wde',
      [DeviceType.Tablet]: 'wde',
      [DeviceType.Mobile]: 'wph'
    };

    const config = $config.get();

    const deviceType =
      (config?.deviceType && deviceTypeMapping[config.deviceType]) ||
      'unknownDevice';

    const pageType = config?.pageType || 'unknownPage';

    async function fetchConfigs() {
      const configs = await RelevantDigital.Api.execute((api) =>
        api.getConfigs()
      );

      const pageConfig = configs.find(({ configId }) => configId);

      if (!pageConfig?.adUnits) {
        setError('No placements found for the current page.');
        return;
      }

      const { adUnits, ...generalConfig } = pageConfig;
      setGeneralConfig(generalConfig);

      const filtered = adUnits.filter(
        ({ gamPath }) =>
          gamPath?.includes(deviceType) && gamPath.includes(pageType)
      );

      if (filtered.length === 0) {
        setError('No matching placements found.');
        return;
      }

      setFilteredPlacements(filtered);
    }

    fetchConfigs().catch((error: unknown) => {
      debugLog('Error fetching configurations', error);
      setError('Error fetching configurations');
    });
  }, []);

  if (error) return <p>Error: {error}</p>;

  return (
    <div className="ml-8 mr-8 flex flex-col gap-4">
      {generalConfig && (
        <div>
          <h4>General Configurations:</h4>
          <ObjectExplorer data={generalConfig} />
        </div>
      )}

      <div className="mt-16">
        <h4>Placements:</h4>
        {filteredPlacements?.length ? (
          filteredPlacements.map((placement, index) => (
            <div key={index} className="mt-16">
              <h5>
                Placement ID: {placement.placementId} | GAM Path:{' '}
                {placement.gamPath}
              </h5>
              <ObjectExplorer data={placement} />
            </div>
          ))
        ) : (
          <p>Loading relevant placements...</p>
        )}
      </div>
    </div>
  );
}

export namespace RelevantTab {
  export const friendlyName = 'Relevant';

  export type GeneralConfig = Omit<RelevantDigital.Api.Config, 'adUnits'>;
}
