import { ConsentStatus, debugLog } from '@schibsted-nmp/advertising-shared';

const CONSENT_TIMEOUT_IN_MS = 10000; // 10 seconds

function subscribeToConsent(cb: (consentValue: boolean) => void): void {
  if (typeof window === 'undefined') return;
  window._tcf_?.isConsentedToAll(cb);
}

function getConsentSync(): boolean | null {
  if (typeof window === 'undefined') return null;
  return window._tcf_?.getConsentedToAllSync() ?? null;
}

export function waitForConsent(): Promise<ConsentStatus> {
  return new Promise((resolve) => {
    const consentValueSync = getConsentSync();
    const consentNotSetYet = consentValueSync === null;

    if (!consentNotSetYet) {
      debugLog('Consent value sync: ', consentValueSync);

      resolve(
        consentValueSync ? ConsentStatus.Accepted : ConsentStatus.Rejected
      );

      return;
    }

    let cancelled = false;

    const timer = setTimeout(() => {
      cancelled = true;
      debugLog(
        `Consent timeout after ${CONSENT_TIMEOUT_IN_MS}ms, defaulting to rejected`
      );
      resolve(ConsentStatus.Rejected);
    }, CONSENT_TIMEOUT_IN_MS);

    subscribeToConsent((consentValueAsync) => {
      if (!cancelled) {
        clearTimeout(timer);
        debugLog('Consent value async: ', consentValueAsync);
        resolve(
          consentValueAsync ? ConsentStatus.Accepted : ConsentStatus.Rejected
        );
      }
    });
  });
}
