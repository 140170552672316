import Glimr from 'glimr-sdk';

import localStorage from '@client/core/utils/localStorage.js';
import {
  Brand,
  ConsentStatus,
  debugLog,
  type GamKeyValue
} from '@schibsted-nmp/advertising-shared';

import { $glimrTagsAtom } from '../atoms/config.js';
import { $consentAtom } from '../atoms/consent.js';

// TODO: fix wrong usage of localStorage and array values (localStorage only supports string values - use proper serialization).

export const GLIMR_TAG_STORAGE_KEY = 'advertising-podlet.glimrTags';

function getClientIdByBrand(brand: Brand) {
  switch (brand) {
    case Brand.Blocket:
      return 'AM3UZB2HBZHD9R7M55JS';
    case Brand.Tori:
      return 'CQSKY6V5814SQJ61XFJQ';
    case Brand.Finn:
      return 'ESA0AB9PATGZ2AGWICEX';
    case Brand.Dba:
      return 'UYSCXA35QWYC4D4CWBC1';
    default:
      return '';
  }
}

export function clearPersistedGlimrTags() {
  localStorage.clearValue(GLIMR_TAG_STORAGE_KEY);
}

export function getPersistedGlimrTags(): Array<string> {
  return (
    (localStorage.getItem(GLIMR_TAG_STORAGE_KEY) as Array<string> | null) ?? []
  );
}

export function setPersistedGlimrTags(tags: string[]) {
  $glimrTagsAtom.set(tags);
  localStorage.setItem(GLIMR_TAG_STORAGE_KEY, tags);
}

export async function loadGlimrIfPermission(brand: Brand) {
  const consent = $consentAtom.get();

  if (consent === ConsentStatus.Accepted) {
    const glimrTags = await loadGlimr({ brand });
    $glimrTagsAtom.set(glimrTags.tags);
    setPersistedGlimrTags(glimrTags.tags);
  } else {
    clearPersistedGlimrTags();
  }
}

export function getGlimrTags(tags: string[]): GamKeyValue[] {
  return [
    {
      key: 'glimr_weather',
      value: tags
    },
    {
      key: 'glimr_geo',
      value: tags
    }
  ];
}

export async function loadGlimr({
  brand
}: {
  brand: Brand;
}): Promise<loadGlimr.Value> {
  debugLog('Load glimr');

  const clientId = getClientIdByBrand(brand);
  if (!clientId) return { tags: [], tagMappings: {} };

  Glimr.setTagCacheTimeInSeconds(300);

  const { promise, resolve } = Promise.withResolvers<loadGlimr.Value>();

  Glimr.getTags(clientId, (tags, tagMappings) => {
    resolve({ tags, tagMappings });
  });

  return await promise;
}

export namespace loadGlimr {
  export type Value = {
    tags: Array<string>;
    tagMappings: Record<string, unknown>;
  };
}
