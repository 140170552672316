import { atom } from '@podium/store';
import type { GamKeyValue } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $gamTargetingAtom = atom<$gamTargetingAtom.Value>(
  AtomChannels.Podlet,
  AtomTopics.GamTargeting,
  []
);

export namespace $gamTargetingAtom {
  export type Value = Array<GamKeyValue>;
}

/**
 * Updates the global targeting state with new key-value pairs.
 *
 * The function merges the current targeting state with the new targeting values, ensuring:
 * - Keys are unique in the final state.
 * - If a key already exists, its values are merged with the new values.
 * - Duplicate values for the same key are removed.
 *
 * @param gamTargeting - An array of GamKeyValue objects to add to the global targeting state.
 */
export const setGamTargetingAtom = (gamTargeting: Array<GamKeyValue>) => {
  const currentTargeting = $gamTargetingAtom.value ?? [];
  const combinedTargeting = [...currentTargeting, ...gamTargeting];

  // Create a map to merge values with the same key:
  const targetingMap = combinedTargeting.reduce<Map<string, string[]>>(
    (acc, item) => {
      const existingValues = acc.get(item.key) ?? [];
      const newValues = Array.isArray(item.value) ? item.value : [item.value];

      // Merge and deduplicate values:
      const mergedValues = Array.from(
        new Set([...existingValues, ...newValues])
      );

      acc.set(item.key, mergedValues);

      return acc;
    },
    new Map()
  );

  // Convert the map back to an array of GamKeyValue objects:
  const uniqueKeysTargeting = Array.from(targetingMap.entries()).map(
    ([key, value]): GamKeyValue => ({ key, value })
  );

  $gamTargetingAtom.set(uniqueKeysTargeting);
};
