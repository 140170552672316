import React, { forwardRef, useEffect, useState } from 'react';

import {
  debugLog,
  type LayoutType,
  type PlacementId
} from '@schibsted-nmp/advertising-shared';

import { $placementsMap, getPlacementList } from '../atoms/placements.js';
import { injectSlotInWebComponent } from '../utils/injectSlot.js';

export type Props = {
  itemId: PlacementId;
  webComponentName: string;
  categories?: string;
  initialLayoutType?: LayoutType;
};

/**
 * Allows to inject a slot into a web component. This is used to get an element from the Light DOM drilling into the Shadow DOM for 1 level deep.
 *
 * @itemId - The id of the item to get the placementId for.
 * @categories - The categories of the item to get the placementId for. Stringified JSON object.
 * @initialLayoutType - The initial layout type of the item to get the placementId for. (Grid or List)
 * @webComponentName - The name of the web component to inject the slot into.
 */
export const AdvertisingSlotComponent = forwardRef<HTMLDivElement, Props>(
  (props: Props) => {
    const { itemId, categories, initialLayoutType, webComponentName } = props;

    const placementId = itemId;
    const placementsList = getPlacementList();
    const placement = placementsList.find(
      (placement) => placement.placementId === placementId
    );

    if (!placement) {
      debugLog(
        `Placement is not in placementMap for placementId: ${placementId}`
      );
      return null;
    }

    const adIndex = placementsList.indexOf(placement);

    const containerId = `${placementId}--container`;
    const slotId = `${placementId}-slot`;

    const [slotAdded, setSlotAdded] = useState<boolean>(false);

    // Hide the parent element if the ad fails to load
    useEffect(() => {
      // Unsubscribe from previous listener if it exists
      const unsubscribe = $placementsMap.subscribe((placements) => {
        const placement =
          typeof placements === 'object' && placements[placementId];
        if (placement && ['error', 'loaded'].includes(placement.status)) {
          const parentElement =
            typeof document !== 'undefined'
              ? document
                  .querySelector(webComponentName)
                  ?.shadowRoot?.getElementById(itemId)
              : null;
          if (parentElement) {
            // Makes sure the ad does not take up any space in the grid
            // The children will be empty so it won't take up height etc.
            parentElement.style.gridColumn = '1 / -1';
          }
        }
      });
      // Cleanup when the component unmounts or placementId changes
      return () => {
        unsubscribe();
      };
    }, [placementId, itemId]); // Add placementId to the dependency array

    useEffect(() => {
      if (!slotAdded && Boolean(placementId)) {
        injectSlotInWebComponent({
          placementId,
          containerId,
          adIndex,
          categories,
          initialLayoutType,
          webComponentName,
          slotId
        });
        setSlotAdded(true);
      }
    }, [
      slotAdded,
      placementId,
      adIndex,
      containerId,
      categories,
      initialLayoutType,
      webComponentName
    ]);

    return <slot name={slotId} />;
  }
);
