import {
  type AdnAdUnit,
  AdPlacement,
  debugLog,
  type PlacementId,
  PlacementStatus
} from '@schibsted-nmp/advertising-shared';
import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import {
  sizeExceedsCenterContentWidth,
  sizeIsAdnami
} from '@client/adManager/util.js';
import { isMiddleBannerOverlappingFilters } from '@client/core/utils/dom/handleAdsOverlap/isMiddleBannerOverlappingFilters.js';

export const setupAdnEventListeners = (adUnits: AdnAdUnit[]) => {
  for (const adUnit of adUnits) {
    updatePlacementKeyValueById(
      adUnit.targetId as PlacementId,
      'status',
      'requested'
    );
  }

  window.adn.requestData({
    adUnits,
    onSuccess: ({ responseJSON }) => {
      for (const adUnit of responseJSON.adUnits) {
        const placementId = adUnit.targetId as PlacementId;
        const hasFilled = adUnit.matchedAdCount > 0;
        if (hasFilled) {
          const [ad] = adUnit.ads;

          if (ad && handleMiddleSlotAndCheckIsOverlapping(ad, placementId)) {
            continue;
          }

          debugLog(`Adnuntius ad loaded for placement: ${placementId}`, adUnit);

          if (
            placementId === AdPlacement.AdvtTop1.id &&
            ad &&
            sizeIsAdnami([Number(ad.creativeWidth), Number(ad.creativeHeight)])
          ) {
            updatePlacementKeyValueById(placementId, 'creativeType', 'adnami');
          }

          if (ad && sizeIsDbaMobileAdnIntermingle(ad)) {
            updatePlacementKeyValueById(placementId, 'creativeType', 'banner');
          }

          updatePlacementKeyValueById(placementId, 'status', 'loaded');
          updateMetricByKey(placementId, PlacementStatus.AdLoaded);
        } else {
          debugLog(`Adnuntius ad did not fill for placement: ${placementId}`);
          updateMetricByKey(placementId, PlacementStatus.AdNoFill);
          updatePlacementKeyValueById(placementId, 'status', 'error');
        }
      }
    }
  });
};

function handleMiddleSlotAndCheckIsOverlapping(
  adUnit: AdnAdUnit,
  placementId: PlacementId
) {
  const isMiddleSlot = placementId.startsWith('advt_middle_');
  if (!isMiddleSlot) return false;

  // creativeWidth and height are set as string from adn reponse
  const size = [Number(adUnit.creativeWidth), Number(adUnit.creativeHeight)];

  if (!sizeExceedsCenterContentWidth(size)) return false;

  if (isMiddleBannerOverlappingFilters(placementId)) {
    debugLog(
      `Adnuntius ad overlaps with search filters: ${placementId}`,
      adUnit
    );
    updateMetricByKey(placementId, PlacementStatus.CollapsedOnCollision);
    updatePlacementKeyValueById(placementId, 'status', 'collapsedOnCollision');
    return true;
  }
  updatePlacementKeyValueById(placementId, 'creativeType', 'intermingles');
  return false;
}

const validDbaMobileAdnIntermingleSizes = [
  [320, 320],
  [300, 160],
  [300, 230],
  [300, 240],
  [300, 250]
];

function sizeIsDbaMobileAdnIntermingle(adUnit: AdnAdUnit): boolean {
  const width = Number(adUnit.creativeWidth);
  const height = Number(adUnit.creativeHeight);

  return validDbaMobileAdnIntermingleSizes.some(
    ([w, h]) => width === w && height === h
  );
}
