import React, { useEffect, useState } from 'react';

import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { debugLog, type LayoutType } from '@schibsted-nmp/advertising-shared';

import { useAdComponents } from './useAdComponents.js';
import { useIntermingles } from './useIntermingles.js';
import { useSearchPositionInstruction } from './useSearchPositionInstruction.js';

export type UseAdPositionElements = {
  layoutType: LayoutType;
};

export function useAdPositionElements({ layoutType }: UseAdPositionElements) {
  const [bannerPositions, setBannerPositions] = useState(new Array<number>());

  const [bannerElements, setBannerElements] = useState(
    new Array<React.ReactNode>()
  );

  const interminglePlacements = useIntermingles();

  const positionData = useSearchPositionInstruction({
    layoutType,
    interminglePlacements
  });

  const adList = useAdComponents({ layoutType, positionData });

  useEffect(() => {
    if (!positionData.length) return;
    setBannerPositions(positionData.map(({ position }) => position));
  }, [positionData]);

  useEffect(() => {
    messageBus.publish(
      events.COMPANION.channel,
      events.COMPANION.LAYOUT_TYPE_CHANGE.topic,
      { layoutType }
    );
  }, [layoutType]);

  /**
   * Make sure returned React component is persisted and memoized
   */
  useEffect(() => {
    if (!adList.length) return;

    debugLog(
      `adList received. Setting positions and elements from advertising-companion`,
      adList
    );

    if (!bannerPositions.length) {
      const bannerPositions = adList.map(({ position }) => position);

      debugLog(
        `setBannerPositions from advertising-companion. Memoized so it will only happen once`,
        bannerPositions
      );

      setBannerPositions(bannerPositions);
    }

    if (!bannerElements.length) {
      const bannerElements = adList.map(({ element }) => element);

      debugLog(
        `setBannerElements from advertising-companion. Memoized so it will only happen once`,
        bannerElements
      );

      setBannerElements(bannerElements);
    }
  }, [adList, setBannerPositions, setBannerElements]);

  return [bannerPositions, bannerElements] as const;
}
