import { atom, type WritableAtom } from '@podium/store';
import type { AdnKeyValue } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $adnTargetingAtom: WritableAtom<$adnTargetingAtom.Value> = atom<
  Array<AdnKeyValue>
>(AtomChannels.Podlet, AtomTopics.AdnTargeting, []);

export namespace $adnTargetingAtom {
  export type Value = Array<AdnKeyValue>;
}

/**
 * Updates the global targeting state with new key-value pairs for Adnuntius.
 *
 * The function merges the current targeting state with the new targeting values, ensuring:
 * - Keys are unique in the final state.
 * - If a key already exists, its values are merged with the new values.
 * - Duplicate values for the same key are removed.
 *
 * @param adnTargeting - An array of AdnKeyValue objects to add to the global targeting state.
 */
export function setAdnTargetingAtom(adnTargeting: Array<AdnKeyValue>) {
  const currentTargeting = $adnTargetingAtom.value ?? [];
  const newAdnTargeting = adnTargeting;
  const combinedTargeting = [...currentTargeting, ...newAdnTargeting];

  // Create a map to merge values with the same key
  const targetingMap = combinedTargeting.reduce((acc, item) => {
    for (const [key, newValues] of Object.entries(item)) {
      const existingValues = acc.get(key) ?? [];

      // Merge and deduplicate values
      const mergedValues = Array.from(
        new Set([...existingValues, ...newValues])
      );

      acc.set(key, mergedValues);
    }

    return acc;
  }, new Map<string, Array<string>>());

  // Convert the map back to an array of AdnKeyValue objects
  const uniqueKeysTargeting = Array.from(
    targetingMap.entries()
  ).map<AdnKeyValue>(([key, values]) => ({ [key]: values }));

  $adnTargetingAtom.set(uniqueKeysTargeting);
}
