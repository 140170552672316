import type { AdConfig, AdnKeyValue } from '../api/index.js';

export type AdnTag = {
  calls: Array<() => void>;
  requestData: AdnRequestData;
  setFeedbackOptions: (obj: { console: string; inScreen: string }) => void;
  request: (config: { adUnits: AdnAdUnit[] }) => void;

  util: {
    addEventListener(
      object: object,
      eventName: string,
      handler: (event: unknown) => void
    ): void;
  };
};

type AdnRequestData = (config: {
  adUnits: Array<AdnAdUnit>;

  onSuccess?(data: {
    responseJSON: ResponseJSON;
    responseCode: number;
    responseText: string;
  }): void;

  onError?(data: {
    errorText: string;
    responseCode: number;
    responseText: string;
  }): void;

  onResponse?(data: {
    responseJSON?: ResponseJSON;
    responseCode: number;
    responseText: string;
  }): void;
}) => void;

type ResponseJSON = {
  adUnits: Array<{
    matchedAdCount: number;
    targetId: string;
    ads: Array<AdnAdUnit>;
  }>;
};

export type AdnAdUnit = {
  auId: string;
  targetId: string;
  kv: Array<AdnKeyValue>;
  creativeWidth?: string;
  creativeHeight?: string;
};

// atm config server is sending kv's as an object, we need them as an array
export const transformAdnKeyValuesToArray = (adConfig: AdConfig) => {
  const adnKv = adConfig.adServer.adn?.kv ?? {};

  return Object.entries(adnKv).map<AdnKeyValue>(([key, values]) => ({
    [key]: values.flat()
  }));
};
