export function ensurePathStartsWithSlash(path: string): string {
  return path.startsWith('/') ? path : `/${path}`;
}

export function removeWallpaperDiv() {
  const wallpaperDiv =
    typeof document !== 'undefined' &&
    document.getElementById('wallpaper-container');

  if (wallpaperDiv) wallpaperDiv.remove();
}
