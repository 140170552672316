import React from 'react';

import { css, unsafeCSS } from 'lit';

import { lightGray } from '@client/dev/components/utils/colors.js';

import { Static } from './Static.js';
import { Expandable } from './Expandable.js';
import { Entry } from './Entry.js';

export function ObjectExplorer(props: ObjectExplorer.Props) {
  return (
    <div className="object-explorer__container text-xs">
      <Entry data={props.data} />
    </div>
  );
}

export namespace ObjectExplorer {
  export type Props = { data: unknown };

  export const styles = [
    css`
      .object-explorer__container {
        --object-explorer__active-color: ${unsafeCSS(lightGray)};
        --object-explorer__inactive-color: ${unsafeCSS(lightGray)};
        --object-explorer__actions-button-height: 18px;
        --object-explorer__actions-padding: 6px;
        --object-explorer__indentation-width: 30px;

        font-family: Monaco, monospace;
        width: fit-content;
        margin-top: var(--object-explorer__actions-button-height);
        white-space: pre;
      }
    `,
    ...Static.styles,
    ...Expandable.styles
  ];
}
