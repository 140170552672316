import React, { forwardRef, useEffect, useState } from 'react';

import type { LayoutType } from '@schibsted-nmp/advertising-shared';

import { $placementsMap } from '../atoms/placements.js';
import { useRecommendationIds } from '../hooks/useRecommendationIds.js';
import { injectSlotInWebComponent } from '../utils/injectSlot.js';

export type Props = {
  itemId: string;
  webComponentName: string;
  categories?: string;
  initialLayoutType?: LayoutType;
};

/**
 * Allows to inject a slot into a web component. This is used to get an element from the Light DOM drilling into the Shadow DOM for 1 level deep.
 *
 * @itemId - The id of the item to get the placementId for.
 * @categories - The categories of the item to get the placementId for. Stringified JSON object.
 * @initialLayoutType - The initial layout type of the item to get the placementId for. (Grid or List)
 * @webComponentName - The name of the web component to inject the slot into.
 */
export const AdvertisingNativeSlotComponent = forwardRef<HTMLDivElement, Props>(
  (props: Props) => {
    const { itemId, categories, initialLayoutType, webComponentName } = props;
    const { placementId, containerId, adIndex } = useRecommendationIds({
      itemId: props.itemId
    });

    const recircSlotId = `${placementId}--recirc-slot`;
    const [recircSlotAdded, setRecircSlotAdded] = useState<boolean>(false);

    // Hide the parent element if the ad fails to load
    useEffect(() => {
      // Unsubscribe from previous listener if it exists
      const unsubscribe = $placementsMap.subscribe((placements) => {
        const placement =
          typeof placements === 'object' && placements[placementId];
        if (placement && placement.status === 'error') {
          const parentElement =
            typeof document !== 'undefined'
              ? document
                  .querySelector(webComponentName)
                  ?.shadowRoot?.getElementById(itemId)
              : null;
          if (parentElement) {
            // Makes sure the ad does not take up any space in the grid
            // The children will be empty so it won't take up height etc.
            parentElement.style.gridColumn = '1 / -1';
          }
        }
      });
      // Cleanup when the component unmounts or placementId changes
      return () => {
        unsubscribe();
      };
    }, [placementId, itemId]); // Add placementId to the dependency array

    useEffect(() => {
      if (!recircSlotAdded && placementId) {
        injectSlotInWebComponent({
          placementId,
          containerId,
          adIndex,
          categories,
          initialLayoutType,
          webComponentName,
          slotId: recircSlotId
        });
        setRecircSlotAdded(true);
      }
    }, [
      recircSlotAdded,
      placementId,
      adIndex,
      containerId,
      categories,
      initialLayoutType,
      webComponentName
    ]);

    return <slot name={recircSlotId} />;
  }
);
