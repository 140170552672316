import React from 'react';

import type { GamAdUnitProps } from '@client/adManager/AdUnit/GamAdUnit.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import { events, messageBus } from '@schibsted-nmp/advertising-events';

type Props = GamAdUnitProps & {
  children: React.ReactNode;
};

export function RenderSponsoredContentOrChildren(props: Props) {
  const { children: backFillAd, placement } = props;
  const { placementId } = placement;
  const [loadBackFill, setLoadBackFill] = React.useState(false);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleContentDecision = (hasContent: boolean) => {
      if (hasContent) {
        updatePlacementKeyValueById(placementId, 'status', 'loaded');
        updatePlacementKeyValueById(
          placementId,
          'creativeType',
          'sponsored-content'
        );
      } else {
        updatePlacementKeyValueById(placementId, 'status', 'pending');
        updatePlacementKeyValueById(placementId, 'creativeType', 'banner');
        setLoadBackFill(true);
      }
    };

    // Check for existing messages first
    const peekEvent = messageBus.peek(
      events.PODLET.channel,
      'render-sponsored-content'
    );

    if (peekEvent?.payload !== undefined) {
      handleContentDecision(Boolean(peekEvent.payload));
    } else {
      // Set timeout only if we're waiting for a message
      timeoutId = setTimeout(() => {
        handleContentDecision(false);
      }, 1000);
    }

    // Subscribe to future messages
    const unsubscribe = messageBus.subscribe(
      events.PODLET.channel,
      'render-sponsored-content',
      (event) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
        handleContentDecision(Boolean(event.payload));
      }
    );

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      unsubscribe();
    };
  }, [placementId]);

  if (loadBackFill) return backFillAd;
  return <contentful-sov-fetcher adPosition={placementId} />;
}
