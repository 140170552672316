// atomConstants.ts

import { $adnTargetingAtom } from '@client/core/atoms/adnTargeting.js';
import { setInitialPlacementStatusMetrics } from '@client/core/atoms/metrics.js';
import {
  AdServer,
  type AfsPlacement,
  Brand,
  type ClientAdPlacement,
  ConsentStatus,
  devToolToggled,
  type InitialState,
  setAdvtState,
  transformAdnKeyValuesToArray
} from '@schibsted-nmp/advertising-shared';
import { $wallpaperConfig } from '@client/core/atoms/wallpaperConfigAtom.js';
import { addInhouseHighImpactBanner } from '@client/core/utils/dom/handleInhouseHighImpactBanner.js';

import { $afsAtom } from './afs.js';
import {
  $config,
  $initialState,
  $ppidAtom,
  getEmptyOrPersistedPPID
} from './config.js';
import { $gamTargetingAtom } from './gamTargeting.js';
import {
  $placementsMap,
  $statusSequence,
  addStatusToSequence,
  getPlacementList,
  listenPlacementKeysForValueChange
} from './placements.js';
import { $unleashFeaturesAtom } from './unleashFeatures.js';
import { $consentAtom } from './consent.js';
import { $amplitudeExperimentsAtom } from './amplitude.js';

export enum AtomChannels {
  Podlet = 'advertising-podlet'
}

export enum AtomTopics {
  PlacementsMap = 'placementsMap',
  PPID = 'ppid',
  Glimr = 'glimr',
  Cycle = 'cycle',
  GamTargeting = 'gamTargeting',
  AdnTargeting = 'adnTargeting',
  PrebidLoadedList = 'prebidLoadedList',
  Config = 'config',
  Afs = 'afs',
  PlacementStatusMetrics = 'placementStatusMetrics',
  UnleashFeatures = 'unleashFeatures',
  SearchFilters = 'searchFilters',
  InitialState = 'initialState',
  WaitForClient = 'waitForClient',
  PlacementMetricSequence = 'placementMetricSequence',
  AdStatusSequence = 'adStatusSequence',
  SignificantEvents = 'significantEvents',
  Consent = 'consent',
  AmplitudeExperiments = 'amplitudeExperiments'
}

const allAdServers = [AdServer.Afs, AdServer.Adn, AdServer.Gam];
const consentedAdServers = [AdServer.Afs, AdServer.Gam];
const nonConsentedAdServers = [AdServer.Adn];

const allowedAdServersWithConsent = {
  dba: {
    consent: consentedAdServers,
    noConsent: nonConsentedAdServers
  }
};

export function setInitialGlobalAtoms(
  initialState: InitialState,
  consent: ConsentStatus
) {
  $config.set(initialState.config);
  $ppidAtom.set(getEmptyOrPersistedPPID());
  $gamTargetingAtom.set(initialState.config.adServer.gam?.targeting ?? []);
  $adnTargetingAtom.set(transformAdnKeyValuesToArray(initialState.config));
  $consentAtom.set(consent);

  const isDba = initialState.config.brand === Brand.Dba;

  const allowedAdservers = isDba
    ? allowedAdServersWithConsent.dba[
        consent === ConsentStatus.Accepted ? 'consent' : 'noConsent'
      ]
    : allAdServers;

  const allowedPlacements = initialState.config.placements.filter((placement) =>
    allowedAdservers.includes(placement.adServer.type as AdServer)
  );

  const placementsMap: Record<string, ClientAdPlacement> =
    allowedPlacements.reduce<Record<string, ClientAdPlacement>>(
      (map, placement) => {
        map[placement.placementId] = {
          ...placement,
          status: 'pending',
          creativeType: undefined,
          statusSequence: []
        } as const satisfies ClientAdPlacement;

        return map;
      },
      {}
    );

  if (devToolToggled() || initialState.env === 'local') {
    for (const placement of allowedPlacements) {
      listenPlacementKeysForValueChange(
        placement.placementId,
        'status',
        (status, oldStatus) => {
          if (status !== oldStatus) {
            addStatusToSequence(placement.placementId, status);
          }
        }
      );
    }
  }

  $placementsMap.set(placementsMap);

  $afsAtom.set({
    config: initialState.config.adServer.afs ?? null,

    placements: initialState.config.placements.filter(
      (placement): placement is AfsPlacement =>
        placement.adServer.type === AdServer.Afs
    )
  });

  setInitialPlacementStatusMetrics(getPlacementList());
  $unleashFeaturesAtom.set(initialState.unleashFeatures);
  $amplitudeExperimentsAtom.set(initialState.amplitudeExperiments);

  $initialState.set({
    env: initialState.env,
    baseUrl: initialState.baseUrl,
    logSessionId: initialState.logSessionId,
    locale: initialState.locale,
    unleashFeatures: initialState.unleashFeatures,
    amplitudeExperiments: initialState.amplitudeExperiments,
    config: initialState.config
  });
}

function makeState(): ADVT.State {
  function state() {
    return state as ADVT.State;
  }

  Object.defineProperties(state, {
    initialState: {
      get() {
        return $initialState.get();
      },
      enumerable: true
    },

    config: {
      get() {
        return $config.get();
      },
      enumerable: true
    },

    ppid: {
      get() {
        return $ppidAtom.get();
      },
      enumerable: true
    },

    placements: {
      get() {
        return $placementsMap.get();
      },
      enumerable: true
    },

    afs: {
      get() {
        return $afsAtom.get();
      },
      enumerable: true
    },

    unleashFeatures: {
      get() {
        return $unleashFeaturesAtom.get();
      },
      enumerable: true
    },

    gamTargeting: {
      get() {
        return $gamTargetingAtom.get();
      },
      enumerable: true
    },

    adnTargeting: {
      get() {
        return $adnTargetingAtom.get();
      },
      enumerable: true
    },

    statusSequence: {
      get() {
        return $statusSequence.get();
      },
      enumerable: true
    }
  } as const satisfies {
    [TKey in keyof ADVT.State]: PropertyDescriptor & {
      get(): ADVT.State[TKey];
    };
  });

  return state as ADVT.State;
}

setAdvtState('state', makeState());

// setWallpaperConfig will be removed once the creative is updated to post message to eventListener
function setWallpaperConfig(...args: Parameters<typeof $wallpaperConfig.set>) {
  $wallpaperConfig.set(...args);
}

setAdvtState('setWallpaperConfig', setWallpaperConfig);
setAdvtState('addInhouseHighImpactBanner', addInhouseHighImpactBanner);

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ADVT {
    setWallpaperConfig: typeof setWallpaperConfig;
    addInhouseHighImpactBanner: typeof addInhouseHighImpactBanner;
  }

  namespace ADVT {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface State {
      readonly config: $config.Value;
      readonly initialState: $initialState.Value;
      readonly ppid: $ppidAtom.Value;
      readonly placements: $placementsMap.Value;
      readonly afs: $afsAtom.Value;
      readonly unleashFeatures: $unleashFeaturesAtom.Value;
      readonly gamTargeting: $gamTargetingAtom.Value;
      readonly adnTargeting: $adnTargetingAtom.Value;
      readonly statusSequence: $statusSequence.Value;
    }
  }
}
