import { debounce } from 'throttle-debounce';

import { $config, $initialState } from '@client/core/atoms/config.js';
import {
  $placementStatusMetrics,
  setInitialPlacementStatusMetrics
} from '@client/core/atoms/metrics.js';
import fetcher from '@client/core/utils/api/fetcher.js';
import {
  AdServer,
  debugLog,
  type ErrorMetricsBody,
  type PlacementId,
  type PlacementStatusMetric,
  type PlacementStatusMetricsBody
} from '@schibsted-nmp/advertising-shared';

import { getPlacementById, getPlacementList } from '../atoms/placements.js';

export const debouncedSendMetrics = debounce(3000, () => {
  sendMetricsToServer().catch(() => void 0);
});

function getAdServerByPlacementId(
  placementId: PlacementId
): AdServer | undefined {
  const placement = getPlacementById(placementId);
  return placement?.adServer.type as AdServer;
}

export async function sendMetricsToServer() {
  const { pageType, deviceType, vertical, subvertical } = $config.get() ?? {};

  const placementStatuses = new Array<PlacementStatusMetric>();

  for (const placementStatus of $placementStatusMetrics.get()) {
    if (
      !placementStatuses.some(
        ({ placementId }) => placementId === placementStatus.placementId
      )
    ) {
      placementStatuses.push(placementStatus);
    }
  }

  // send metrics to server
  try {
    const adServer = placementStatuses[0]
      ? getAdServerByPlacementId(placementStatuses[0].placementId)
      : undefined;

    await fetcher('/api/metrics/placement-status', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify({
        placementStatuses,
        deviceType: deviceType ?? 'unknown',
        pageType: pageType ?? 'unknown',
        vertical: vertical || 'unknown',
        subvertical: subvertical || 'unknown',
        adServer: adServer ?? 'unknown'
      } as const satisfies PlacementStatusMetricsBody)
    });

    setInitialPlacementStatusMetrics(getPlacementList());
  } catch (error) {
    debugLog('Failed to send metrics to server', error);
  }
}

export async function sendErrorMessageMetricToServer(errorMessage: string) {
  const { pageType, deviceType, vertical, subvertical } = $config.get() ?? {};
  const { logSessionId } = $initialState.get();

  try {
    if (errorMessage) {
      await fetcher(`/api/metrics/client-error`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },

        body: JSON.stringify({
          logSessionId,
          errorMessage,
          deviceType: deviceType ?? 'unknown',
          pageType: pageType ?? 'unknown',
          vertical: vertical || 'unknown',
          subvertical: subvertical || 'unknown'
        } as const satisfies ErrorMetricsBody)
      });
    }
  } catch (error) {
    console.error('Failed to send error message to server', error);
  }
}
