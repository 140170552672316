import { initiateGam } from '@client/adManager/initiateGam.js';
import { initiateAdn } from '@client/adnuntius/initiateAdn.js';
import { setupEvents } from '@client/core/utils/events/index.js';
import { defineAdvtComponent } from '@client/core/components/webComponents/index.js';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics.js';
import { persistPPID } from '@client/core/utils/pulse/pulse.js';
import {
  Brand,
  ConsentStatus,
  debugLog,
  DeviceType,
  type InitialState
} from '@schibsted-nmp/advertising-shared';
import { initiateHandleStickySkyscrapers } from '@client/core/utils/dom/handleStickySkyscrapers.js';
import { initiateHandleWallpaper } from '@client/core/utils/dom/handleWallpaper.js';
import {
  sendAmplitudeExperimentToGam,
  setupAmplitudeExperimentSubscription
} from '@client/core/utils/amplitude/index.js';

import { loadGlimrIfPermission } from './core/utils/glimr.js';
import './dev/AdvtDevTool';
import { $consentAtom } from './core/atoms/consent.js';

/**
 * Initiates ad rendering, setup page options and handles each placement.
 * @param initialState The initial state for setting up ads.
 */
export async function initiateAdvt(initialState: InitialState): Promise<void> {
  try {
    debugLog('Inside initiateAdvt()');

    const { config } = initialState;

    await Promise.all([persistPPID(), loadGlimrIfPermission(config.brand)]);

    setupEvents();

    if (config.deviceType === DeviceType.Desktop) {
      initiateHandleStickySkyscrapers();
    }

    initiateHandleWallpaper();
    defineAdvtComponent();

    // Send Amplitude experiment values to GAM and set up subscription for updates
    sendAmplitudeExperimentToGam();
    setupAmplitudeExperimentSubscription();

    const consent = $consentAtom.get();
    if (config.adServer.gam) {
      if (consent === ConsentStatus.Accepted || config.brand !== Brand.Dba) {
        initiateGam(config.adServer.gam);
      }
    }

    if (consent === ConsentStatus.Rejected && config.adServer.adn) {
      initiateAdn(config.adServer.adn);
    }

    debugLog('Advt initiated');
  } catch (error) {
    console.error('Failed to initiate ads', error);

    if (error instanceof Error) {
      sendErrorMessageMetricToServer(`Failed to initiate ads ${error.message}`)
        .then(() => {
          debugLog('Sent error message metric to server');
        })
        .catch((error: unknown) => {
          console.error('Failed to send error message metric to server', error);
        });
    }
  }
}
