import { atom } from '@podium/store';
import type {
  AfsConfig,
  AfsPlacement
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

type AfsAtom = {
  placements: Array<AfsPlacement>;
  config: AfsConfig | null;
};

export const $afsAtom = atom<$afsAtom.Value>(
  AtomChannels.Podlet,
  AtomTopics.Afs,
  {
    placements: [],
    config: null
  }
);

export namespace $afsAtom {
  export type Value = AfsAtom;
}
