import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import {
  type AdType,
  debugLog,
  type PlacementId,
  PlacementStatus
} from '@schibsted-nmp/advertising-shared';
import { isMiddleBannerOverlappingFilters } from '@client/core/utils/dom/handleAdsOverlap/isMiddleBannerOverlappingFilters.js';

import {
  eventIsNativeCpmAd,
  sizeIsAdnami,
  sizeIsFullScreenAd,
  sizeIsNativeAd,
  sizeIsDbaDesktopIntermingle,
  sizeExceedsCenterContentWidth
} from '../util.js';

// TODO: there's no reason for event listeners to be set up per-placement - it creates a bunch of overhead and we should refactor it to be set up once for all slots:
export function setupGamEventListeners(slot: googletag.Slot) {
  if (!window.googletag) {
    throw new Error(
      'Failed setting up gam event listeners due to googletag not loaded'
    );
  }

  const placementId = slot.getSlotElementId() as PlacementId;

  // Fired when an ad has been requested for the ad slot:
  window.googletag.pubads().addEventListener('slotRequested', (event) => {
    // Skip events that are not related to the current slot:
    if (!isPlacementEventElement(placementId, event.slot)) return;

    debugLog(
      `Google ad requested for placement: ${placementId} with slot`,
      event.slot
    );

    updatePlacementKeyValueById(placementId, 'creativeType', 'banner');
    updatePlacementKeyValueById(placementId, 'status', 'requested');
    updateMetricByKey(placementId, PlacementStatus.AdRequested);
  });

  // Fired when creative code has been injected into an ad slot:
  window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
    // Skip events that are not related to the current slot:
    if (!isPlacementEventElement(placementId, event.slot)) return;

    if (event.isEmpty) {
      debugLog(
        `Google ad did not fill for placement: ${placementId} with slot`,
        event.slot
      );

      updateMetricByKey(placementId, PlacementStatus.AdNoFill);
      updatePlacementKeyValueById(placementId, 'status', 'error');

      return;
    }

    const isMiddleSlot = slot.getSlotElementId().startsWith('advt_middle_');

    if (isMiddleSlot && shouldCollapseMiddleAd(event.size, placementId)) {
      debugLog(
        `Google ad overlaps with search filters: ${placementId} with slot`,
        event.slot
      );

      updateMetricByKey(placementId, PlacementStatus.CollapsedOnCollision);

      updatePlacementKeyValueById(
        placementId,
        'status',
        'collapsedOnCollision'
      );

      return;
    }

    const creativeType = getCreativeType(event, isMiddleSlot);

    debugLog(
      `Creative type from size ${JSON.stringify(event.size)}: ${creativeType}`
    );

    updatePlacementKeyValueById(placementId, 'creativeType', creativeType);
    updatePlacementKeyValueById(placementId, 'status', 'loaded');
    updateMetricByKey(placementId, PlacementStatus.AdLoaded);
  });

  // Fired when an impression becomes viewable:
  window.googletag.pubads().addEventListener('impressionViewable', (event) => {
    // Skip events that are not related to the current slot:
    if (!isPlacementEventElement(placementId, event.slot)) return;

    updateMetricByKey(placementId, PlacementStatus.AdViewed);
  });

  // Fired when the on-screen percentage of the ad slot changes:
  window.googletag
    .pubads()
    .addEventListener('slotVisibilityChanged', (event) => {
      // Skip events that are not related to the current slot:
      if (!isPlacementEventElement(placementId, event.slot)) return;

      debugLog(
        `PlacementId: ${placementId}: slotVisibilityChanged event:`,
        event
      );
    });

  // Fired when a creative iframe fires its onload event:
  window.googletag.pubads().addEventListener('slotOnLoad', (event) => {
    // Skip events that are not related to the current slot:
    if (!isPlacementEventElement(placementId, event.slot)) return;

    debugLog(`PlacementId: ${placementId}: slotOnLoad event:`, event);
  });
}

export function isPlacementEventElement(
  placementId: PlacementId,
  slot: googletag.Slot
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  return placementId === slot.getSlotElementId();
}

function getCreativeType(
  event: googletag.events.SlotRenderEndedEvent,
  isMiddleSlot: boolean
): AdType {
  const { size } = event;

  // Check ad types in order of specificity
  if (isMiddleSlot && sizeIsDbaDesktopIntermingle(size)) return 'intermingles';
  if (sizeIsAdnami(size)) return 'adnami';
  if (sizeIsFullScreenAd(size)) return 'takeover';
  if (eventIsNativeCpmAd(event)) return 'sponsored-content';
  if (sizeIsNativeAd(size)) return 'native';

  return 'banner';
}

function shouldCollapseMiddleAd(
  adSize: googletag.events.SlotRenderEndedEvent['size'],
  placementId: PlacementId
): boolean {
  return (
    sizeExceedsCenterContentWidth(adSize) &&
    isMiddleBannerOverlappingFilters(placementId)
  );
}
