import React, { forwardRef } from 'react';

import type {
  LayoutType,
  PlacementId,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';

import { useRecommendationIds } from '../hooks/useRecommendationIds.js';

export type Props = {
  itemId: string;
  categories?: RecommendationCategories;
  initialLayoutType?: LayoutType;
};

export const AdvertisingRecircItemPageComponent = forwardRef<
  HTMLDivElement,
  Props
>(({ itemId, initialLayoutType, categories }: Props, ref) => {
  const { placementId, adIndex, containerId, slotId } = useRecommendationIds({
    itemId
  });

  if (adIndex === -1) return <div />;

  return (
    <advt-component
      containerId={containerId}
      initialLayoutType={initialLayoutType}
      categories={categories}
      adIndex={adIndex}
      placementId={placementId as PlacementId}
    >
      <div ref={ref} id={containerId} slot={slotId}></div>
    </advt-component>
  );
});
