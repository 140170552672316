import { debugLog, type InitialState } from '@schibsted-nmp/advertising-shared';

export function getInitialState() {
  try {
    const jsonScript = document.getElementById('advertising-initial-state');

    if (jsonScript) {
      return JSON.parse(jsonScript.innerHTML) as InitialState;
    }

    return getNullWithLogs('Missing InitialState');
  } catch (error) {
    console.warn('Failed parsing initial state', error);
    return getNullWithLogs('Missing InitialState');
  }
}

export function hasInitialPlacements(initialState: InitialState) {
  const result = initialState.config.placements.length > 0;
  if (!result) getNullWithLogs('No placements exists');
  return result;
}

function getNullWithLogs(log: string) {
  debugLog(log);
  return null;
}
