import type { PlacementId } from '@schibsted-nmp/advertising-shared';

export type ContainerId = `${PlacementId}--container`;
export type AdSlot = `${PlacementId}--slot`;

export {
  AdPlacement,
  type PlacementId
} from '@schibsted-nmp/advertising-shared';

export {
  type UseAdPositionElements,
  useAdPositionElements
} from './hooks/useAdPositionElements.js';
export { useIsBannerLoaded } from './hooks/useIsBannerLoaded.js';

export { AdvertisingWebComponent } from './components/AdvertisingWebComponent.js';
export { AdvertisingSlotComponent } from './components/AdvertisingSlotComponent.js';
export { AdvertisingAfsWebComponent } from './components/AdvertisingAfsWebComponent.js';
export { AdvertisingClientWebComponent } from './components/AdvertisingClientWebComponent.js';
export { AdvertisingRecircItemPageComponent } from './components/AdvertisingRecircItemPageComponent.js';
export { AdvertisingNativeSlotComponent } from './components/AdvertisingNativeSlotComponent.js';

export { insertBanners } from './utils/insertBanners.js';
export { getPlacementList } from './atoms/placements.js';
export { placementHasIntermingle } from './atoms/placements.js';
