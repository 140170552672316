import { $wallpaperConfig } from '@client/core/atoms/wallpaperConfigAtom.js';
import {
  fetchAllElements,
  fetchElement,
  styleElements
} from '@client/core/utils/dom/domUtils.js';
import { debugLog } from '@schibsted-nmp/advertising-shared';

const mainContentsQueries = [
  '#mobility-item-page-root',
  '#app',
  '.page-container',
  '#frontpage-content'
];

let observer: MutationObserver | null;
let clickEvent: (event: MouseEvent) => void;

function isSafeGoogleSafeFrameOrigin(origin: string): boolean {
  try {
    const url = new URL(origin);
    return url.hostname.endsWith('.safeframe.googlesyndication.com');
  } catch {
    return false;
  }
}

export function initiateHandleWallpaper() {
  window.addEventListener(
    'message',
    (
      event: MessageEvent<{ type?: string; config: $wallpaperConfig.Value }>
    ) => {
      // called from wallpaper creative to initate wallpaper
      // this will listen on all global post messages
      // but we want to filter it down to -> allowed origins and setWallpaper type
      if (isSafeGoogleSafeFrameOrigin(event.origin)) {
        if (event.data.type === 'setWallpaper') {
          $wallpaperConfig.set(event.data.config);
        }
      }
    }
  );

  $wallpaperConfig.subscribe((config) => {
    if (!config.stickySkyscrapers) {
      handleSkyscraperStickySetting(false);
    }

    clickEvent = (event) => {
      if (event.defaultPrevented) return;
      window.open(config.clickThroughUrl, '_blank');
    };

    if (config.wallpaperImage || config.wallpaperHtml) {
      const { body } = document;
      body.style.pointerEvents = 'none';
      const wallpaperContainer = document.createElement('div');

      wallpaperContainer.id = 'advt-wallpaper-container';
      wallpaperContainer.style.zIndex = '-1';
      wallpaperContainer.style.pointerEvents = 'all';
      wallpaperContainer.style.top = `51px`;
      wallpaperContainer.style.backgroundPosition = 'center top';

      wallpaperContainer.style.height = config.stickyWallpaper
        ? '100vh'
        : '1080px';

      wallpaperContainer.style.position = config.stickyWallpaper
        ? 'fixed'
        : 'absolute';

      const wallpaperDiv = document.createElement(
        config.wallpaperHtml ? 'iframe' : 'div'
      );

      if (config.wallpaperImage) {
        wallpaperDiv.style.backgroundImage = `url(${config.wallpaperImage})`;
      } else if (config.wallpaperHtml) {
        (wallpaperDiv as HTMLIFrameElement).src = config.wallpaperHtml;
      }

      wallpaperDiv.style.width = '100%';
      wallpaperDiv.style.height = '100%';
      wallpaperDiv.style.pointerEvents = 'none';
      wallpaperDiv.style.backgroundPosition = 'center top';

      document.body.insertBefore(wallpaperContainer, document.body.firstChild);
      wallpaperContainer.appendChild(wallpaperDiv);

      const header = fetchElement('finn-topbar');
      if (header) header.style.marginBottom = '0';

      const mainContents = fetchAllElements(
        `body > *:not(script)${mainContentsQueries.map((query) => `:not(${query})`).join('')}`
      );

      const mainContents2 = fetchAllElements(
        mainContentsQueries.map((query) => `body > ${query}`).join(',')
      );

      requestAnimationFrame(() => {
        for (const content of mainContents) content.style.pointerEvents = 'all';

        for (const content of mainContents2) {
          for (const child of Array.from(content.children)) {
            if (!(child instanceof HTMLElement)) continue;

            if (child.id !== 'banner-top-container') {
              child.style.pointerEvents = 'all';
            } else {
              child.style.pointerEvents = 'none';

              const actualTopBanner = child.children[0] as
                | HTMLHtmlElement
                | undefined;

              if (actualTopBanner) actualTopBanner.style.pointerEvents = 'all';
            }
          }
        }
      });

      if (config.clickThroughUrl) {
        wallpaperContainer.style.cursor = 'pointer';
        wallpaperContainer.addEventListener('click', clickEvent);
      } else {
        wallpaperContainer.style.cursor = 'default';
      }
    }

    observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for (const node of mutation.addedNodes) {
          if (!(node instanceof HTMLElement)) continue;
          if (node.nodeType !== Node.ELEMENT_NODE) continue;
          if (node.tagName !== 'SCRIPT') node.style.pointerEvents = 'all';
        }
      }
    });

    observer.observe(document.body, { childList: true });

    const parentElement = fetchElement('#advt_wallpaper');

    if (parentElement) {
      const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          for (const node of mutation.removedNodes) {
            if (
              node instanceof HTMLIFrameElement ||
              node instanceof HTMLScriptElement
            ) {
              debugLog('Iframe or script removed! Running cleanup...');
              cleanup();
            }
          }

          // Check if the iframe/script is no longer in the DOM
          const iframeExists = parentElement.querySelector('iframe');
          const scriptExists = parentElement.querySelector('script');

          if (!iframeExists && !scriptExists) {
            debugLog('No iframe or script found! Running cleanup...');
            cleanup();
          }
        }
      });

      observer.observe(parentElement, { childList: true, subtree: true });
    }

    debugLog('wallpaper intiate');
  });
}

function handleSkyscraperStickySetting(sticky: boolean) {
  const stickyElements = fetchAllElements(
    '.banners__sticky--1 .banners__skyscraper'
  );

  styleElements(stickyElements, {
    position: sticky ? 'sticky' : 'static'
  });
}

export function cleanup() {
  debugLog('wallpaper cleanup');

  const wallpaperElement = fetchElement('#advt-wallpaper-container');
  if (wallpaperElement) wallpaperElement.remove();

  if (observer) {
    observer.disconnect();
    observer = null;
  }

  window.removeEventListener('beforeunload', cleanup);
  handleSkyscraperStickySetting(true);
}
