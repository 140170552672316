import React from 'react';

import { Brand } from '@schibsted-nmp/advertising-shared';
import { $config, $initialState } from '@client/core/atoms/config.js';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures.js';

function FeatureFlagList({
  title,
  features,
  bgColor,
  getUnleashUrl
}: FeatureFlagList.Props) {
  return (
    <div>
      <h6 className="font-semibold mb-2">{title}</h6>

      {features.length === 0 ? (
        <div className="text-gray-500">No {title.toLowerCase()}</div>
      ) : (
        <div className="flex flex-col gap-1">
          {features.map((feature) => (
            <a
              href={`${getUnleashUrl()}/strategies/advertising-podlet.${feature}`}
              key={feature}
              target="_blank"
              rel="noreferrer"
              className={`${bgColor} px-2 py-1 rounded-md w-fit`}
            >
              {feature}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

namespace FeatureFlagList {
  export type Props = {
    title: string;
    features: Array<string>;
    bgColor: string;
    getUnleashUrl: () => string;
  };
}

export function FeatureFlagTab() {
  const allFeatureFlags = getAllFeatureStatuses();

  const features = Object.keys(allFeatureFlags) as Array<
    keyof typeof allFeatureFlags
  >;

  const enabledFeatureFlags = features.filter(
    (feature) => allFeatureFlags[feature]
  );

  const disabledFeatureFlags = features.filter(
    (feature) => !allFeatureFlags[feature]
  );

  return (
    <div className="ml-8 mr-8 flex flex-col gap-4">
      <h5>Feature Flags</h5>

      <h6>Unleash URL:</h6>

      <a href={getUnleashUrl()}>{getUnleashUrl()}</a>

      <div className="flex flex-col gap-4">
        <FeatureFlagList
          title="Enabled Features:"
          features={enabledFeatureFlags}
          bgColor="bg-green-100"
          getUnleashUrl={getUnleashUrl}
        />

        <FeatureFlagList
          title="Disabled Features:"
          features={disabledFeatureFlags}
          bgColor="bg-red-100"
          getUnleashUrl={getUnleashUrl}
        />
      </div>
    </div>
  );
}

function getUnleashUrl(): string {
  const { brand } = $config.get() ?? {};
  const { env } = $initialState.get();

  let unleashDevDomain = '';
  const _env = env === 'prod' ? 'prod' : 'dev';
  switch (brand) {
    case Brand.Dba: {
      unleashDevDomain = _env === 'dev' ? 'dev.dba.dk' : 'dba.dk';
      break;
    }

    case Brand.Finn: {
      unleashDevDomain = `${_env}.finn.no`;
      break;
    }

    case Brand.Tori: {
      unleashDevDomain = _env === 'dev' ? 'toripg.rc.nmp.finn.no' : 'tori.fi';
      break;
    }

    default: {
      unleashDevDomain = 'prod.finn.no';
      break;
    }
  }

  return `https://unleash.svc.${unleashDevDomain}/#/features`;
}

FeatureFlagTab.friendlyName = 'Flags' as const;
