type Props = {
  placementId: string;
  containerId?: string | undefined;
  adIndex: number;
  categories?: string | undefined;
  initialLayoutType?: string | undefined;
  webComponentName?: string | undefined;
  slotId: string;
};

export function injectSlotInWebComponent(props: Props) {
  if (typeof window === 'undefined') return;

  const { webComponentName, placementId, slotId } = props;

  if (!webComponentName) return;

  const webComponent = document.querySelector(webComponentName);

  if (!webComponent) return;
  if (!placementId) return;

  const advtComponent = getAdvtWebComponent(props);
  advtComponent.setAttribute('slot', slotId);
  advtComponent.setAttribute('id', slotId);
  webComponent.appendChild(advtComponent);
}

function getAdvtWebComponent({
  placementId,
  containerId,
  adIndex,
  categories,
  initialLayoutType
}: Props) {
  const advtComponent = document.createElement('advt-component');
  advtComponent.setAttribute('placementId', placementId);
  advtComponent.setAttribute('adIndex', `${adIndex}`);

  advtComponent.setAttribute(
    'containerId',
    containerId || `${placementId}--container`
  );

  advtComponent.setAttribute('categories', `${categories}`);
  advtComponent.setAttribute('initialLayoutType', initialLayoutType || 'grid');

  return advtComponent;
}
