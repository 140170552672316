import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import {
  fetchAllElements,
  fetchElement,
  fetchElementById
} from '@client/core/utils/dom/domUtils.js';
import {
  type PlacementId,
  PlacementStatus
} from '@schibsted-nmp/advertising-shared';

const FILTER_LIST_ID = 'advertising--filters-bottom-marker';
const MARGIN = 100;

const fetchFilterListElement = () => fetchElementById(FILTER_LIST_ID);

function isOverlapping(
  middleElement: Element,
  filterListElement: Element
): boolean {
  const filterBottomPlacement =
    filterListElement.getBoundingClientRect().bottom + window.scrollY + MARGIN;

  const elementTop = middleElement.getBoundingClientRect().top + window.scrollY;

  return elementTop < filterBottomPlacement;
}

export function isMiddleBannerOverlappingFilters(placementId: PlacementId) {
  if (!placementId.startsWith('advt_middle_')) return false;

  const filterListElement = fetchFilterListElement();
  if (!filterListElement) return false;

  const middleElement = fetchElement(
    `advt-component[placementid="${placementId}"]`
  );
  if (!middleElement) return false;

  return isOverlapping(middleElement, filterListElement);
}

export function onExpandableUpdateCheckMiddleBannerOverlap() {
  const filterListElement = fetchFilterListElement();
  if (!filterListElement) return false;

  // query, find all advt_middle_x with the class 'intermingles-ad', we know its the dba intermingle sized banners
  // and also check for advt-loaded to make sure the ad is done with its cycle, and this way we won't check against collapsed banners
  const middleElements = fetchAllElements(
    'advt-component[placementid^="advt_middle_"].intermingles-ad.advt-loaded'
  );

  if (middleElements.length === 0) return false;

  const hasOverlap = Array.from(middleElements).some((middleElement) => {
    if (!isOverlapping(middleElement, filterListElement)) return false;

    // a bit hacky, but hey we'r advertising team
    const placementId = middleElement.getAttribute(
      'placementid'
    ) as PlacementId | null;

    if (!placementId) return false;

    updateMetricByKey(placementId, PlacementStatus.CollapsedOnCollision);
    updatePlacementKeyValueById(placementId, 'status', 'collapsedOnCollision');

    return true;
  });

  return hasOverlap;
}
