import type { Brand } from '@schibsted-nmp/advertising-shared';
import type { Script } from '@client/core/utils/Script.js';

export namespace Scripts {
  export const Config = {
    bilbasen: null,
    blocket: null,
    dba: 'https://macro.adnami.io/macro/spec/adsm.macro.d1be15b8-7ada-45b8-81b7-e2dc40458ca4.js',
    finn: null,
    oikotie: null,
    rakentaja: null,
    tori: 'https://functions.adnami.io/api/macro/adsm.macro.beta.tori.fi.js'
  } as const satisfies Config;

  export type Config = Readonly<Record<Brand, Script.Url | null>>;

  export function* get(brand: Brand): Generator<Script, void, void> {
    const src = Config[brand];
    if (src) yield { name: 'adnami', src };
  }
}
