import React from 'react';

import classNames from 'classnames';

import { $amplitudeExperimentsAtom } from '@client/core/atoms/amplitude.js';

function ExperimentRow({
  experimentKey,
  variant,
  payload
}: ExperimentRow.Props) {
  const variantType = variant.toLowerCase();
  const isControl = variantType === 'candidate_a';
  const isTreatment = variantType === 'candidate_b';

  return (
    <div
      className={classNames('cursor-pointer s-border p-16 rounded-4', {
        's-border-info s-bg-info-subtle': isControl,
        's-border-positive s-bg-positive-subtle': isTreatment,
        's-border-warning s-bg-warning-subtle': !isControl && !isTreatment
      })}
    >
      <div className="s-text font-bold">{experimentKey}</div>

      <div className="mt-8 s-text-subtle">
        Variant:{' '}
        <span
          className={classNames('s-text', {
            's-text-info': isControl,
            's-text-positive': isTreatment,
            's-text-warning': !isControl && !isTreatment
          })}
        >
          {variant}
        </span>
      </div>

      {payload ? (
        <div className="mt-8">
          <div className="s-text-subtle">Payload:</div>
          <pre className="mt-4 s-bg p-8 rounded-4 overflow-auto s-border">
            {JSON.stringify(payload, null, 2)}
          </pre>
        </div>
      ) : null}
    </div>
  );
}

namespace ExperimentRow {
  export type Props = {
    experimentKey: string;
    variant: string;
    payload?: unknown;
  };
}

export function ExperimentsTab() {
  const experiments = $amplitudeExperimentsAtom.get();
  const experimentCount = Object.keys(experiments.experiments).length;

  return (
    <div className="ml-8 mr-8 flex flex-col gap-4">
      <div className="mb-16">
        <strong className="s-text">Experiments ({experimentCount})</strong>
      </div>

      <div className="flex flex-col gap-8">
        {Object.entries(experiments.experiments).map(([key, value]) => (
          <ExperimentRow
            key={key}
            experimentKey={key}
            variant={value || ''}
            payload={experiments.payloads[key]}
          />
        ))}
        {experimentCount === 0 && (
          <div className="s-text-subtle text-center p-16 s-bg-subtle rounded-4">
            No active experiments
          </div>
        )}
      </div>
    </div>
  );
}

ExperimentsTab.friendlyName = 'Experiments' as const;
