import { atom, type WritableAtom } from '@podium/store';
import {
  type AdServer,
  type ClientAdPlacement,
  type PlacementId,
  PlacementStatus,
  type PlacementStatusMetric
} from '@schibsted-nmp/advertising-shared';

import { getPlacementById, getPlacementList } from './placements.js';

import { AtomChannels, AtomTopics } from './index.js';

export const $placementStatusMetrics: WritableAtom<
  Array<PlacementStatusMetric>
> = atom<Array<PlacementStatusMetric>>(
  AtomChannels.Podlet,
  AtomTopics.PlacementStatusMetrics,
  []
);

export const $lifecycleEvents: WritableAtom<Array<string>> = atom<
  Array<string>
>(AtomChannels.Podlet, AtomTopics.SignificantEvents, []);

export function addLifecycleEvent(event: string) {
  $lifecycleEvents.set([...$lifecycleEvents.get(), event]);
}

export function updateMetricByKey(
  placementId: PlacementId,
  key: PlacementStatus
) {
  const oldMetrics = $placementStatusMetrics.get();
  let placementExists = false;

  const updatedMetrics = oldMetrics.map<PlacementStatusMetric>((oldMetric) => {
    if (oldMetric.placementId === placementId) {
      placementExists = true;

      // Only add the key if it doesn't already exist in statuses
      if (!oldMetric.statuses.includes(key)) {
        return {
          ...oldMetric,
          statuses: [...oldMetric.statuses, key]
        };
      }
    }

    return oldMetric;
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!placementExists) {
    const adServer = getPlacementById(placementId)?.adServer.type as
      | AdServer
      | undefined;

    updatedMetrics.push({
      adServer,
      placementId,
      statuses: [key]
    });
  }

  $placementStatusMetrics.set(updatedMetrics);
}

export function setInitialPlacementStatusMetrics(
  placements: ClientAdPlacement[]
) {
  $placementStatusMetrics.set([]);

  for (const placement of placements) {
    updateMetricByKey(placement.placementId, PlacementStatus.InConfig);
  }
}

export function updateMetricsForAllPlacements(status: PlacementStatus) {
  const currentMetrics = $placementStatusMetrics.get();
  const allPlacements = getPlacementList();

  const updatedMetrics = allPlacements.map((placement) => {
    const existingMetric = currentMetrics.find(
      (metric) => metric.placementId === placement.placementId
    );

    if (existingMetric) {
      return {
        ...existingMetric,
        statuses: [...existingMetric.statuses, status]
      } as const satisfies PlacementStatusMetric;
    }

    const adServer = placement.adServer.type as AdServer;

    return {
      adServer,
      placementId: placement.placementId,
      statuses: [status]
    } as const satisfies PlacementStatusMetric;
  });

  $placementStatusMetrics.set(updatedMetrics);
}
