import {
  type AdConfigPlacement,
  type AdnPlacement,
  AdServer,
  type AfsPlacement,
  type GamPlacement
} from './types/api/index.js';

export namespace Placements.Afs {
  export function is(placement: AdConfigPlacement): placement is AfsPlacement {
    return placement.adServer.type === AdServer.Afs;
  }

  export function filter(placements: ReadonlyArray<AdConfigPlacement>) {
    return placements.filter(is);
  }
}

export namespace Placements.Gam {
  export function is(placement: AdConfigPlacement): placement is GamPlacement {
    return placement.adServer.type === AdServer.Gam;
  }

  export function filter(placements: ReadonlyArray<AdConfigPlacement>) {
    return placements.filter(is);
  }
}

export namespace Placements.Adn {
  export function is(placement: AdConfigPlacement): placement is AdnPlacement {
    return placement.adServer.type === AdServer.Adn;
  }

  export function filter(placements: ReadonlyArray<AdConfigPlacement>) {
    return placements.filter(is);
  }
}
