import { i18n, type Locale } from '@lingui/core';

import { messages as enMessages } from './locales/en/messages.js';
import { messages as fiMessages } from './locales/fi/messages.js';
import { messages as nbMessages } from './locales/nb/messages.js';
import { messages as daMessages } from './locales/da/messages.js';

export const loadAndActivateI18n = (locale: Locale) => {
  i18n.load({ en: enMessages, fi: fiMessages, nb: nbMessages, da: daMessages });
  i18n.activate(locale);
  return i18n;
};
