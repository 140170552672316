import type { GamKeyValue } from '@schibsted-nmp/advertising-shared';

export function setTargetingOnSlotOrGlobal({
  targeting,
  slot,
  global
}: {
  targeting: Array<GamKeyValue>;
  slot?: googletag.Slot | null;
  global?: boolean;
}) {
  for (const target of targeting) {
    if (target.value.length === 0) continue;

    if (slot) {
      slot.setTargeting(target.key, target.value);
    } else if (global) {
      window.googletag?.pubads().setTargeting(target.key, target.value);
    }
  }
}
