import {
  AfsPlacementId,
  DisplayPlacementId,
  FinnLegendaryAfsPlacementId,
  FinnLegendaryDisplayPlacementId,
  type PlacementId
} from './api/index.js';

const placementIdEnums = [
  AfsPlacementId,
  DisplayPlacementId,
  FinnLegendaryAfsPlacementId,
  FinnLegendaryDisplayPlacementId
] as const;

type PlacementIdEnumType = (typeof placementIdEnums)[number];

type PlacementIdEntry<TPlacementId extends PlacementIdEnumType> = {
  [TKey in keyof TPlacementId]: [TKey, TPlacementId[TKey]];
}[keyof TPlacementId];

type CombinedPlacementEntry<
  TPlacementIds extends ReadonlyArray<PlacementIdEnumType>
> = {
  [TKey in keyof TPlacementIds]: PlacementIdEntry<TPlacementIds[TKey]>;
}[number];

const adPlacementEntries = placementIdEnums
  .flatMap<CombinedPlacementEntry<typeof placementIdEnums>>(
    (placementIdEnum) =>
      Object.entries(placementIdEnum) as Array<
        PlacementIdEntry<typeof placementIdEnum>
      >
  )
  .map(
    ([enumKey, placementIdValue]) =>
      [
        enumKey,
        {
          id: placementIdValue,
          containerId: `${placementIdValue}--container`,
          slot: `${placementIdValue}--slot`
        }
      ] as AdPlacement.Entry<typeof placementIdEnums>
  );

export type AdPlacement = {
  [TKey in (typeof adPlacementEntries)[number] as TKey[0]]: TKey[1];
};

export namespace AdPlacement {
  export type ContainerId<T extends PlacementId = PlacementId> =
    `${T}--container`;

  export type AdSlot<T extends PlacementId = PlacementId> = `${T}--slot`;

  export type GenericValue<TId extends PlacementId = PlacementId> = {
    id: TId;
    containerId: ContainerId<TId>;
    slot: AdSlot<TId>;
  };

  export type Value = AdPlacement[keyof AdPlacement];

  export type Entry<TPlacementIds extends ReadonlyArray<PlacementIdEnumType>> =
    {
      [TKey in keyof TPlacementIds]: {
        [TKey2 in keyof TPlacementIds[TKey]]: TPlacementIds[TKey][TKey2] extends PlacementId
          ? [TKey2, GenericValue<TPlacementIds[TKey][TKey2]>]
          : never;
      }[keyof TPlacementIds[TKey]];
    }[number];
}

export const AdPlacement = Object.fromEntries(
  adPlacementEntries
) as AdPlacement;
