import {
  type AdnConfig,
  type AdnTag,
  debugLog
} from '@schibsted-nmp/advertising-shared';

export function initiateAdn(adnConfig: AdnConfig) {
  // TODO: refactor to use Api like relevantDigital/Api.ts:
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.adn ??= {} as AdnTag;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.adn.calls ??= [];

  const eventNames = [
    'render',
    'request',
    'load',
    'impression',
    'click',
    'error',
    'complete',
    'visibilityChange'
  ];

  for (const eventName of eventNames) {
    window.adn.util.addEventListener(window, eventName, (event) => {
      debugLog(`Adn event: ${eventName}`, event);
    });
  }

  debugLog('Adn initialised:', adnConfig);
}
