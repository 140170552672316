import { $config } from '@client/core/atoms/config.js';
import { getInterminglePlacements } from '@client/core/atoms/placements.js';
import { setupTravelEvents } from '@client/core/utils/events/setupTravelEvents.js';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  debugLog,
  PageType,
  Vertical
} from '@schibsted-nmp/advertising-shared';

import { refreshKeywords } from '../refreshKeywords.js';
import { onExpandableUpdateCheckMiddleBannerOverlap } from '../dom/handleAdsOverlap/isMiddleBannerOverlappingFilters.js';

export function setupEvents() {
  const { pageType, vertical } = $config.get() ?? {};

  // Keep this for backward compatibility
  const interminglePlacements = getInterminglePlacements();

  debugLog('placementsWithIntermingle: ', interminglePlacements);

  if (interminglePlacements.length > 0) {
    messageBus.publish('advertising-podlet', 'podlet-ready', {
      placementsWithIntermingle: interminglePlacements
    });
  }

  if (vertical === Vertical.Travel) {
    setupTravelEvents();
  } else if (pageType === PageType.Result) {
    messageBus.subscribe(
      events.SEARCH.channel,
      events.SEARCH.FILTER.topic,
      refreshKeywords.sync
    );

    messageBus.subscribe(
      events.SEARCH.channel,
      events.SEARCH.EXPANDABLE_UPDATE.topic,
      onExpandableUpdateCheckMiddleBannerOverlap
    );
  }
}
