import { type RefObject, useEffect, useRef, useState } from 'react';

type InScreenProps = {
  ref: RefObject<HTMLDivElement>;
  threshold?: number;
  rootMargin?: string;
};

type ReturnType = {
  isIntersecting: boolean;
  hasIntersected: boolean;
};

export function useInScreen({
  ref,
  threshold = 0.0,
  rootMargin = '0px'
}: InScreenProps): ReturnType {
  const observer = useRef<IntersectionObserver | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasIntersected, setHasIntersected] = useState(false);

  const onIntersection = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      setIsIntersecting(entry.isIntersecting);
      if (entry.isIntersecting) {
        setHasIntersected(true); // Has intersected at least once
      }
    }
  };

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      observer.current = new IntersectionObserver(onIntersection, {
        root: null, // viewport
        rootMargin,
        threshold
      });

      if (ref.current) observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [ref, rootMargin, threshold]);

  return {
    isIntersecting,
    hasIntersected
  };
}
