import type { Features } from '../types/unleash.js';
import {
  UNLEASH_FEATURE_ARRAY,
  type UnleashFeatureStatuses
} from './featureNames.js';

export class UnleashFeatureManager<TFeatures extends Features = Features> {
  features: TFeatures;

  constructor(features: TFeatures) {
    this.features = features;
  }

  isEnabled(featureName: keyof TFeatures): boolean {
    if (Object.keys(this.features).length === 0) return false;
    return this.features[featureName]?.enabled ?? false;
  }

  getAllFeatureStatuses() {
    return UNLEASH_FEATURE_ARRAY.reduce<UnleashFeatureStatuses>(
      (acc, featureName) => {
        acc[featureName] = this.features[featureName]?.enabled ?? false;
        return acc;
      },
      {}
    );
  }
}
