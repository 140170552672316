/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdConfig {
  vertical_level_1?: Vertical | FinnLegendaryVertical;
  vertical_level_2?: '' | SubVertical | FinnLegendarySubVertical | null;
  vertical_level_3?: '' | VerticalLevel3 | null;
  adServer: AdConfigServer;
  brand: Brand;
  consent?: ConsentStatus | null;
  deviceType: DeviceType;
  pageType: PageType;
  placements: Array<AdConfigPlacement>;
  subvertical?: '' | SubVertical | FinnLegendarySubVertical | null;
  vertical: Vertical | FinnLegendaryVertical;
}

export interface AdConfigIntermingle {
  grid: number | null;
  list: number | null;
}

export type AdConfigPlacement = AdnPlacement | AfsPlacement | GamPlacement;

export interface AdConfigServer {
  adn?: AdnConfig | null;
  afs?: AfsConfig | null;
  gam?: GamConfig | null;
}

export enum AdServer {
  Adn = 'adn',
  Afs = 'afs',
  ContentMarketing = 'content_marketing',
  Gam = 'gam',
  SponsoredContent = 'sponsored-content'
}

export interface AdnConfig {
  kv: AdnKeyValue;
}

export type AdnKeyValue = Record<string, Array<string>>;

export interface AdnPlacement {
  adServer: AdnPlacementAdServer;
  intermingle?: AdConfigIntermingle | null;
  placementId: DisplayPlacementId | FinnLegendaryDisplayPlacementId;
}

export interface AdnPlacementAdServer {
  config: AdnPlacementConfig;
  type: 'adn';
}

export interface AdnPlacementConfig {
  auId: string;
  sizes: Array<DisplaySize>;
  targetId: string;
}

export interface AfsConfig {
  adPage: string;
  pubId: string;
  query: string;
}

export interface AfsPlacement {
  adServer: AfsPlacementAdServer;
  intermingle?: AdConfigIntermingle | null;
  placementId: AfsPlacementId | FinnLegendaryAfsPlacementId;
}

export interface AfsPlacementAdServer {
  config: AfsPlacementConfig;
  type: 'afs';
}

export interface AfsPlacementConfig {
  styleId: string;
}

export enum AfsPlacementId {
  AfsContainer1 = 'afs_container_1',
  AfsContainer2 = 'afs_container_2',
  AfsContainerBottom = 'afs_container_bottom'
}

export enum Brand {
  Bilbasen = 'bilbasen',
  Blocket = 'blocket',
  Dba = 'dba',
  Finn = 'finn',
  Oikotie = 'oikotie',
  Rakentaja = 'rakentaja',
  Tori = 'tori'
}

export interface ConfigRequest {
  adId?: string | null;
  brand: Brand;
  device: DeviceType;
  page: PageType;
  searchFilters?: SearchFilters | null;
  searchKey?: SearchKey | null;
  subvertical?: SubVertical | FinnLegendarySubVertical | null;
  vertical: Vertical | FinnLegendaryVertical;
}

export enum ConsentStatus {
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export enum DeviceType {
  Android = 'android',
  Desktop = 'desktop',
  Ios = 'ios',
  Mobile = 'mobile',
  Tablet = 'tablet'
}

export enum DisplayPlacementId {
  AdvtArticle1 = 'advt_article_1',
  AdvtArticle2 = 'advt_article_2',
  AdvtAudience = 'advt_audience',
  AdvtBottom1 = 'advt_bottom_1',
  AdvtFinancing1 = 'advt_financing_1',
  AdvtLeft1 = 'advt_left_1',
  AdvtLeft2 = 'advt_left_2',
  AdvtMarketing = 'advt_marketing',
  AdvtMiddle1 = 'advt_middle_1',
  AdvtMiddle2 = 'advt_middle_2',
  AdvtMiddle3 = 'advt_middle_3',
  AdvtMiddle4 = 'advt_middle_4',
  AdvtMiddle5 = 'advt_middle_5',
  AdvtRecircMiddle1 = 'advt_recirc_middle_1',
  AdvtRecircMiddle2 = 'advt_recirc_middle_2',
  AdvtRecircMiddle3 = 'advt_recirc_middle_3',
  AdvtRecircMiddle4 = 'advt_recirc_middle_4',
  AdvtRecircMiddle5 = 'advt_recirc_middle_5',
  AdvtRight1 = 'advt_right_1',
  AdvtRight2 = 'advt_right_2',
  AdvtTop1 = 'advt_top_1',
  AdvtTop2 = 'advt_top_2',
  AdvtWallpaper = 'advt_wallpaper'
}

/**
 * @maxItems 2
 * @minItems 2
 */
export type DisplaySize = Array<number>;

export enum FinnLegendaryAfsPlacementId {
  Afscontainer1 = 'afscontainer1'
}

export enum FinnLegendaryDisplayPlacementId {
  AdStreamNativeAd1 = 'ad-stream__native-ad-1',
  AdStreamNativeAd2 = 'ad-stream__native-ad-2',
  Board1 = 'board_1',
  Board2 = 'board_2',
  Board3 = 'board_3',
  Board4 = 'board_4',
  Board5 = 'board_5',
  Contentboard = 'contentboard',
  Netboard1 = 'netboard_1',
  Netboard2 = 'netboard_2',
  Netboard3 = 'netboard_3',
  Netboard4 = 'netboard_4',
  Netboard5 = 'netboard_5',
  Skyscraperleft1 = 'skyscraperleft_1',
  Skyscraperright1 = 'skyscraperright_1',
  Topboard = 'topboard',
  Wallpaper = 'wallpaper'
}

export enum FinnLegendarySubVertical {
  Abroad = 'abroad',
  Agriculturecombines = 'agriculturecombines',
  Agriculturetools = 'agriculturetools',
  Agriculturetractor = 'agriculturetractor',
  All = 'all',
  Atv = 'atv',
  Bus = 'bus',
  Businessplot = 'businessplot',
  Businessrent = 'businessrent',
  Businesssale = 'businesssale',
  Caravan = 'caravan',
  Companyforsale = 'companyforsale',
  Construction = 'construction',
  Docs = 'docs',
  Flight = 'flight',
  Forsale = 'forsale',
  Front = 'front',
  HolidayRental = 'holiday_rental',
  Homes = 'homes',
  Hotel = 'hotel',
  Import = 'import',
  Leisureplots = 'leisureplots',
  Leisuresale = 'leisuresale',
  Lettings = 'lettings',
  Mobilehome = 'mobilehome',
  Motor = 'motor',
  Newbuildings = 'newbuildings',
  Plots = 'plots',
  Prepackage = 'prepackage',
  Rent = 'rent',
  Scooter = 'scooter',
  Snowmobile = 'snowmobile',
  Truck = 'truck',
  Truckabroad = 'truckabroad',
  Used = 'used',
  Vanimport = 'vanimport',
  Vanused = 'vanused',
  Wanted = 'wanted'
}

export enum FinnLegendaryVertical {
  Atv = 'atv',
  Bap = 'bap',
  Car = 'car',
  Job = 'job',
  Mc = 'mc',
  Boat = 'boat',
  B2B = 'b2b',
  Scooter = 'scooter',
  Snowmobile = 'snowmobile'
}

export interface GamConfig {
  adUnitName: string;
  networkId: string;
  targeting: Array<GamKeyValue>;
}

export interface GamKeyValue {
  key: string;
  value: Array<string>;
}

export enum GamMediaType {
  Display = 'display',
  Native = 'native',
  Video = 'video'
}

export interface GamPlacement {
  adServer: GamPlacementAdServer;
  intermingle?: AdConfigIntermingle | null;
  placementId: DisplayPlacementId | FinnLegendaryDisplayPlacementId;
}

export interface GamPlacementAdServer {
  config: GamPlacementConfig;
  type: 'gam';
}

export interface GamPlacementConfig {
  adUnitId: string;
  mediaTypes: Array<SizeMediaType>;
  path: string;
  relevantYield?: GamRelevantYieldPlacementConfig | null;
  sizeMappings?: Array<GamSizeMapping> | null;
  sizes: Array<GamSize>;
  targeting: Array<GamKeyValue>;
}

export interface GamRelevantYieldPlacementConfig {
  accountId: string;
  configId: string;
}

export type GamSize = 'fluid' | Array<number>;

export interface GamSizeMapping {
  breakpoint: DisplaySize;
  sizes: Array<GamSize>;
}

export enum PageType {
  Front = 'front',
  Object = 'object',
  Result = 'result',
  Section = 'section'
}

export type PlacementId =
  | AfsPlacementId
  | DisplayPlacementId
  | FinnLegendaryDisplayPlacementId
  | FinnLegendaryAfsPlacementId;

export enum PlacementStatus {
  AdLoaded = 'ad_loaded',
  AdNoFill = 'ad_no_fill',
  AdRequested = 'ad_requested',
  AdViewed = 'ad_viewed',
  CollapsedOnCollision = 'collapsed_on_collision',
  ElementInDom = 'element_in_dom',
  InConfig = 'in_config',
  NoElementInDom = 'no_element_in_dom',
  NotInConfig = 'not_in_config',
  Refresh = 'refresh',
  SlotCreated = 'slot_created'
}

export interface PlacementTrackingEvent {
  adServer: AdServer;
  placement:
    | AfsPlacementId
    | DisplayPlacementId
    | FinnLegendaryDisplayPlacementId
    | FinnLegendaryAfsPlacementId;
  status: PlacementStatus;
  /** @format int64 */
  timeSinceInitialized?: number | null;
}

export interface PlacementsTrackingRequest {
  device: DeviceType;
  events: Array<PlacementTrackingEvent>;
  page: PageType;
  subvertical?: SubVertical | FinnLegendarySubVertical | null;
  vertical: Vertical | FinnLegendaryVertical;
}

export type SearchFilters = Record<string, string | Array<string>>;

export enum SearchKey {
  SEARCH_ID_AGRICULTURE_THRESHING = 'SEARCH_ID_AGRICULTURE_THRESHING',
  SEARCH_ID_AGRICULTURE_TOOL = 'SEARCH_ID_AGRICULTURE_TOOL',
  SEARCH_ID_AGRICULTURE_TRACTOR = 'SEARCH_ID_AGRICULTURE_TRACTOR',
  SEARCH_ID_BAP_ALL = 'SEARCH_ID_BAP_ALL',
  SEARCH_ID_BAP_COMMON = 'SEARCH_ID_BAP_COMMON',
  SEARCH_ID_BAP_FREE = 'SEARCH_ID_BAP_FREE',
  SEARCH_ID_BAP_GROUP = 'SEARCH_ID_BAP_GROUP',
  SEARCH_ID_BAP_SALE = 'SEARCH_ID_BAP_SALE',
  SEARCH_ID_BAP_WANTED = 'SEARCH_ID_BAP_WANTED',
  SEARCH_ID_BAP_WEBSTORE_SHOP = 'SEARCH_ID_BAP_WEBSTORE_SHOP',
  SEARCH_ID_BOAT_COMMON = 'SEARCH_ID_BOAT_COMMON',
  SEARCH_ID_BOAT_DOCK = 'SEARCH_ID_BOAT_DOCK',
  SEARCH_ID_BOAT_DOCK_WANTED = 'SEARCH_ID_BOAT_DOCK_WANTED',
  SEARCH_ID_BOAT_MOTOR = 'SEARCH_ID_BOAT_MOTOR',
  SEARCH_ID_BOAT_NEW = 'SEARCH_ID_BOAT_NEW',
  SEARCH_ID_BOAT_PARTS = 'SEARCH_ID_BOAT_PARTS',
  SEARCH_ID_BOAT_PARTS_MOTOR_WANTED = 'SEARCH_ID_BOAT_PARTS_MOTOR_WANTED',
  SEARCH_ID_BOAT_RENT = 'SEARCH_ID_BOAT_RENT',
  SEARCH_ID_BOAT_RENT_WANTED = 'SEARCH_ID_BOAT_RENT_WANTED',
  SEARCH_ID_BOAT_USED = 'SEARCH_ID_BOAT_USED',
  SEARCH_ID_BOAT_USED_WANTED = 'SEARCH_ID_BOAT_USED_WANTED',
  SEARCH_ID_CAR_AGRI = 'SEARCH_ID_CAR_AGRI',
  SEARCH_ID_CAR_BUS = 'SEARCH_ID_CAR_BUS',
  SEARCH_ID_CAR_CARAVAN = 'SEARCH_ID_CAR_CARAVAN',
  SEARCH_ID_CAR_MOBILE_HOME = 'SEARCH_ID_CAR_MOBILE_HOME',
  SEARCH_ID_CAR_PARALLEL_IMPORT = 'SEARCH_ID_CAR_PARALLEL_IMPORT',
  SEARCH_ID_CAR_TRUCK = 'SEARCH_ID_CAR_TRUCK',
  SEARCH_ID_CAR_TRUCK_ABROAD = 'SEARCH_ID_CAR_TRUCK_ABROAD',
  SEARCH_ID_CAR_USED = 'SEARCH_ID_CAR_USED',
  SEARCH_ID_CAR_VAN = 'SEARCH_ID_CAR_VAN',
  SEARCH_ID_CAR_VAN_ABROAD = 'SEARCH_ID_CAR_VAN_ABROAD',
  SEARCH_ID_COMMERCIAL_PLOTS = 'SEARCH_ID_COMMERCIAL_PLOTS',
  SEARCH_ID_COMMERCIAL_RENT = 'SEARCH_ID_COMMERCIAL_RENT',
  SEARCH_ID_COMMERCIAL_SALE = 'SEARCH_ID_COMMERCIAL_SALE',
  SEARCH_ID_COMPANY_SALE = 'SEARCH_ID_COMPANY_SALE',
  SEARCH_ID_ESTATE_PROJECT = 'SEARCH_ID_ESTATE_PROJECT',
  SEARCH_ID_JOB_FULLTIME = 'SEARCH_ID_JOB_FULLTIME',
  SEARCH_ID_JOB_MANAGEMENT = 'SEARCH_ID_JOB_MANAGEMENT',
  SEARCH_ID_JOB_PARTTIME = 'SEARCH_ID_JOB_PARTTIME',
  SEARCH_ID_MC_ATV = 'SEARCH_ID_MC_ATV',
  SEARCH_ID_MC_COMMON = 'SEARCH_ID_MC_COMMON',
  SEARCH_ID_MC_SCOOTER = 'SEARCH_ID_MC_SCOOTER',
  SEARCH_ID_MC_SNOWMOBILE = 'SEARCH_ID_MC_SNOWMOBILE',
  SEARCH_ID_MC_USED = 'SEARCH_ID_MC_USED',
  SEARCH_ID_MOTOR_COMMON = 'SEARCH_ID_MOTOR_COMMON',
  SEARCH_ID_REALESTATE_ABROAD_HOMES = 'SEARCH_ID_REALESTATE_ABROAD_HOMES',
  SEARCH_ID_REALESTATE_COMMON = 'SEARCH_ID_REALESTATE_COMMON',
  SEARCH_ID_REALESTATE_HOMES = 'SEARCH_ID_REALESTATE_HOMES',
  SEARCH_ID_REALESTATE_HOMES_BANNER = 'SEARCH_ID_REALESTATE_HOMES_BANNER',
  SEARCH_ID_REALESTATE_LEISURE_PLOTS = 'SEARCH_ID_REALESTATE_LEISURE_PLOTS',
  SEARCH_ID_REALESTATE_LEISURE_SALE = 'SEARCH_ID_REALESTATE_LEISURE_SALE',
  SEARCH_ID_REALESTATE_LETTINGS = 'SEARCH_ID_REALESTATE_LETTINGS',
  SEARCH_ID_REALESTATE_LETTINGS_WANTED = 'SEARCH_ID_REALESTATE_LETTINGS_WANTED',
  SEARCH_ID_REALESTATE_NEWBUILDINGS = 'SEARCH_ID_REALESTATE_NEWBUILDINGS',
  SEARCH_ID_REALESTATE_PLANNEDPROJECT = 'SEARCH_ID_REALESTATE_PLANNEDPROJECT',
  SEARCH_ID_REALESTATE_PLOTS = 'SEARCH_ID_REALESTATE_PLOTS',
  SEARCH_ID_TRAVEL_CURRENCY = 'SEARCH_ID_TRAVEL_CURRENCY',
  SEARCH_ID_TRAVEL_FLIGHT = 'SEARCH_ID_TRAVEL_FLIGHT',
  SEARCH_ID_TRAVEL_HOLIDAYRENTALS = 'SEARCH_ID_TRAVEL_HOLIDAYRENTALS',
  SEARCH_ID_TRAVEL_HOTEL = 'SEARCH_ID_TRAVEL_HOTEL',
  SEARCH_ID_TRAVEL_LASTMINUTE = 'SEARCH_ID_TRAVEL_LASTMINUTE',
  SEARCH_ID_TRAVEL_PREPACKAGE = 'SEARCH_ID_TRAVEL_PREPACKAGE'
}

export interface SizeMediaType {
  height?: number | null;
  type?: GamMediaType | null;
  width?: number | null;
}

export enum SubVertical {
  Boats = 'boats',
  BusinessForrent = 'business-forrent',
  BusinessForsale = 'business-forsale',
  Caravans = 'caravans',
  Cars = 'cars',
  Currency = 'currency',
  Flights = 'flights',
  HolidayHomes = 'holiday-homes',
  Hotels = 'hotels',
  Industry = 'industry',
  Lastminute = 'lastminute',
  LeisureForsale = 'leisure-forsale',
  Motorcycles = 'motorcycles',
  Prepackages = 'prepackages',
  ResidentialForrent = 'residential-forrent',
  ResidentialForsale = 'residential-forsale'
}

/** @example "OK" */
export type V1TrackingPlacementsCreateData = string;

export type V2ConfigCreateData = AdConfig;

export enum Vertical {
  Front = 'front',
  Jobs = 'jobs',
  Mobility = 'mobility',
  Realestate = 'realestate',
  Recommerce = 'recommerce',
  Travel = 'travel'
}

export enum VerticalLevel3 {
  Snowmobile = 'snowmobile',
  Scooter = 'scooter'
}

export namespace Api {
  /**
   * @description Supports operations and alerting on placement delivery
   * @tags Tracking
   * @name V1TrackingPlacementsCreate
   * @summary Placement tracking events ingestion endpoint
   * @request POST:/api/v1/tracking/placements
   * @response `200` `V1TrackingPlacementsCreateData` Successful request
   * @response `400` `void` Violation of expected values, check the request is aligned with the schema
   */
  export namespace V1TrackingPlacementsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PlacementsTrackingRequest;
    export type RequestHeaders = {};
    export type ResponseBody = V1TrackingPlacementsCreateData;
  }

  /**
   * @description Returns ad server and site location specific placement configuration and targeting
   * @tags Config
   * @name V2ConfigCreate
   * @summary Returns ad server and site location specific placement configuration and targeting
   * @request POST:/api/v2/config
   * @response `200` `V2ConfigCreateData` Successful response with ad server configuration and placements
   */
  export namespace V2ConfigCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConfigRequest;
    export type RequestHeaders = {
      /** Consent header value */
      'CMP-Advertising'?: string;
      /** Consent header value */
      'x-nmp-app-brand'?: string;
    };
    export type ResponseBody = V2ConfigCreateData;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://0.0.0.0:8080';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body)
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title Advertising Ad Server Configuration Service API Documentation
 * @version latest
 * @baseUrl http://0.0.0.0:8080
 *
 * Delivers configuration for the ad server and placements relative to site
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Supports operations and alerting on placement delivery
     *
     * @tags Tracking
     * @name V1TrackingPlacementsCreate
     * @summary Placement tracking events ingestion endpoint
     * @request POST:/api/v1/tracking/placements
     * @response `200` `V1TrackingPlacementsCreateData` Successful request
     * @response `400` `void` Violation of expected values, check the request is aligned with the schema
     */
    v1TrackingPlacementsCreate: (
      data: PlacementsTrackingRequest,
      params: RequestParams = {}
    ) =>
      this.request<V1TrackingPlacementsCreateData, void>({
        path: `/api/v1/tracking/placements`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description Returns ad server and site location specific placement configuration and targeting
     *
     * @tags Config
     * @name V2ConfigCreate
     * @summary Returns ad server and site location specific placement configuration and targeting
     * @request POST:/api/v2/config
     * @response `200` `V2ConfigCreateData` Successful response with ad server configuration and placements
     */
    v2ConfigCreate: (data: ConfigRequest, params: RequestParams = {}) =>
      this.request<V2ConfigCreateData, unknown>({
        path: `/api/v2/config`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
}
