import { UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import type { Script } from '@client/core/utils/Script.js';

export namespace Scripts {
  export function* get(): Generator<Script, void, void> {
    if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableAdnuntius)) return;

    yield {
      name: 'adn',
      src: 'https://cdn.adnuntius.com/adn.js',
      defer: true
    };
  }
}
