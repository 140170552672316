import { atom, type WritableAtom } from '@podium/store';
import type { AmplitudeExperiments } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $amplitudeExperimentsAtom: WritableAtom<AmplitudeExperiments> =
  atom<AmplitudeExperiments>(
    AtomChannels.Podlet,
    AtomTopics.AmplitudeExperiments,
    {
      experiments: {},
      payloads: {}
    }
  );
