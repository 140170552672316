import { throttle } from 'throttle-debounce';

import {
  debugLog,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import {
  fetchElement,
  styleElements
} from '@client/core/utils/dom/domUtils.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';

// THIS IS A POC WE ARE TESTING OUT
// TODO: Midscroll
export type InhouseHighImpactBanner = {
  format: 'Topscroll' | 'Midscroll';
  creative: string;
  clickThroughUrl: string;
};

let clickEvent: EventListener;

function toggleTopBanner(hide: boolean) {
  const topBanner = fetchElement('#banner-top-container');
  if (!topBanner) return;
  styleElements(topBanner, {
    display: hide ? 'none' : 'flex'
  });
}

export const addInhouseHighImpactBanner = (config: InhouseHighImpactBanner) => {
  if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableInhouseHighImpactBanners)) {
    return;
  }

  const { creative, clickThroughUrl } = config;
  debugLog('Inhouse High Impact Banner initiated', config);

  toggleTopBanner(true);

  const container = document.createElement('div');
  container.id = 'inhouse-topscroll';
  styleElements(container, {
    height: '80vh',
    clipPath: 'inset(0 0 0 0)',
    cursor: 'pointer'
  });

  const content = document.createElement('iframe');
  content.src = creative;
  styleElements(content, {
    border: '0px',
    verticalAlign: 'bottom',
    height: '100%',
    width: '100%',
    inset: '0',
    position: 'fixed',
    pointerEvents: 'none'
  });

  container.appendChild(content);
  container.appendChild(createCloseArrow());
  document.documentElement.insertBefore(container, document.body);

  clickEvent = (event: Event) => {
    if (!event.defaultPrevented) {
      window.open(clickThroughUrl, '_blank');
    }
  };
  container.addEventListener('click', clickEvent);

  handleScroll(container);
};

export const cleanupInhouseHighImpactBanner = () => {
  const container = document.getElementById('inhouse-topscroll');
  if (container) {
    container.removeEventListener('click', clickEvent);
    container.remove();
    debugLog('Inhouse High Impact Banner removed');
  }
  toggleTopBanner(false);
};

function createCloseArrow(): HTMLButtonElement {
  const arrowDownSvg = `
  <svg width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
    <path d="M19 9l-7 7-7-7"></path>
  </svg>
`;

  const button = document.createElement('button');
  button.innerHTML = arrowDownSvg;
  styleElements(button, {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '26vh',
    width: '50px',
    height: '50px',
    borderRadius: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '#434343 0px 0px 7px'
  });

  button.addEventListener('click', (e) => {
    e.stopPropagation();
    window.scrollTo({
      top: window.innerHeight * 0.8 + 2,
      behavior: 'smooth'
    });
  });
  return button;
}

export const handleScroll = (container: HTMLDivElement): void => {
  setHeaderPosition(container);

  const onScroll = throttle(50, () => {
    setHeaderPosition(container);
  });

  window.addEventListener('scroll', onScroll);
};

const setHeaderPosition = (container: HTMLDivElement): void => {
  const topScrollBottom = container.getBoundingClientRect().bottom;
  const finnTopbar = document.querySelector('finn-topbar');

  if (finnTopbar?.shadowRoot) {
    const header = finnTopbar.shadowRoot.querySelector('header');
    if (header) {
      if (topScrollBottom <= 0) {
        styleElements(header, { top: '0', position: 'fixed' });
      } else {
        header.style.top = '';
        header.style.position = 'relative';
      }
    }
  }
};
