import { $glimrTagsAtom, $ppidAtom } from '@client/core/atoms/config.js';
import { $gamTargetingAtom } from '@client/core/atoms/gamTargeting.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import { getGlimrTags } from '@client/core/utils/glimr.js';
import {
  debugLog,
  type GamConfig,
  type GamKeyValue,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { setTargetingOnSlotOrGlobal } from './targeting.js';

export function initiateGam(gamConfig: GamConfig) {
  // TODO: refactor to use Api like relevantDigital/Api.ts:
  window.googletag ??= {} as googletag.Googletag;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.googletag.cmd ??= [];

  const { googletag } = window;

  // Push all configurations into a single command block -> or else single request wont work
  googletag.cmd.push(() => {
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs(true);
    googletag.pubads().disableInitialLoad();
    googletag.pubads().setCentering(true);

    const enableGamLazyLoading = isFeatureEnabled(
      UNLEASH_FEATURE_NAME.enableGamLazyLoading
    );

    if (enableGamLazyLoading) {
      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 30,
        renderMarginPercent: 10
      });
    }

    $ppidAtom.subscribe((ppidData) => {
      if (!ppidData.delta) return;

      const ppidString = ppidData.delta.ppId1 || ppidData.delta.ppId2;

      debugLog('Setting PPID on Gam', ppidString);

      if (ppidString) googletag.pubads().setPublisherProvidedId(ppidString);
    });

    $glimrTagsAtom.subscribe((glimrTags) => {
      const tags = glimrTags as string[];
      const glimrKeyValue = getGlimrTags(tags);

      debugLog('Setting GLIMR targeting on GAM', glimrKeyValue);
      setTargetingOnSlotOrGlobal({ targeting: glimrKeyValue, global: true });
    });

    // Set global targeting
    $gamTargetingAtom.subscribe((updatedTargeting) => {
      const targeting = updatedTargeting as GamKeyValue[];
      setTargetingOnSlotOrGlobal({ targeting, global: true });
    });

    // Enable services now that everything is set up
    googletag.enableServices();
    debugLog('Google Ad Manager initialized', googletag, gamConfig);
  });
}
