import { $config } from '@client/core/atoms/config.js';
import {
  getPlacementList,
  listenPlacementKeysForValueChange
} from '@client/core/atoms/placements.js';
import {
  fetchAllElements,
  fetchElement,
  fetchElementById,
  getDocumentHeight,
  querySelect,
  styleElements
} from '@client/core/utils/dom/domUtils.js';
import {
  AdPlacement,
  Brand,
  type PlacementId
} from '@schibsted-nmp/advertising-shared';

const BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY = '.banners__sticky-container';
const FOOTER_ELEM_QUERIES = ['finn-footer', 'mfp-make-model-seo-links'];
const offset = 100;
const FOOTER_HEIGHT_PX = 500;
const activeAdnamiPlacementIds = new Array<PlacementId>();
const SECOND_ROW_SKYSCRAPER_HEIGHT = 1080;
const SECOND_ROW_SKYSCRAPER_MARGIN_TOP = 20;

let bannerStickyContainers: NodeListOf<HTMLElement> | null = null;

function fetchStickyContainer() {
  if (!bannerStickyContainers) {
    bannerStickyContainers = fetchAllElements(
      BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY
    );
  }
}

function setSkyscrapersStickyHeight() {
  const { brand, placements } = $config.get() ?? {};

  const hasTwoSkyscraperRows =
    placements?.some(
      (placement) =>
        placement.placementId === AdPlacement.AdvtRight2.id ||
        placement.placementId === AdPlacement.AdvtLeft2.id
    ) ?? false;

  const firstRowStickyContainers = fetchAllElements('.banners__sticky--1');
  const secondRowStickyContainers = fetchAllElements('.banners__sticky--2');

  const isDbaMastercampanionAd =
    brand === Brand.Dba && fetchElement('#advt_wallpaper iframe');

  if (isDbaMastercampanionAd) {
    const skyscraperAds = fetchAllElements(
      '.banners__sticky--1 advt-component'
    );

    styleElements(skyscraperAds, { marginTop: '180px' });
    styleElements(firstRowStickyContainers, { height: 'calc(1080px - 180px)' });
  } else if (hasTwoSkyscraperRows) {
    styleElements(firstRowStickyContainers, { height: '1080px' });
  } else {
    styleElements(firstRowStickyContainers, { height: '100%' });
  }

  if (hasTwoSkyscraperRows) {
    const secondRowSkyscraperHeight =
      SECOND_ROW_SKYSCRAPER_HEIGHT + SECOND_ROW_SKYSCRAPER_MARGIN_TOP;

    styleElements(secondRowStickyContainers, {
      height: `calc(100% - ${secondRowSkyscraperHeight}px)`,
      marginTop: `${secondRowSkyscraperHeight}px`
    });
  } else {
    styleElements(secondRowStickyContainers, { height: '0px' });
  }
}

function setSkyscraperContainerHeight() {
  setSkyscrapersStickyHeight();
  const collidingElements = activeAdnamiPlacementIds
    .map((placementId) => fetchElementById(placementId))
    .filter((placement) => placement !== null);

  const footerElements = FOOTER_ELEM_QUERIES.map((query) =>
    querySelect(query)
  ).filter((element) => element !== null);
  collidingElements.push(...footerElements);

  const [firstCollisionElement] = collidingElements.sort(
    (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
  );

  if (firstCollisionElement) {
    setBannerStickyContainerHeight(
      firstCollisionElement.getBoundingClientRect().top +
        window.scrollY -
        offset
    );
  } else {
    // fallback. eg. if footer element was not found
    setBannerStickyContainerHeight(
      getDocumentHeight() - FOOTER_HEIGHT_PX - offset
    );
  }
}

function setBannerStickyContainerHeight(newHeight: number) {
  fetchStickyContainer();
  if (!bannerStickyContainers) return;

  for (const container of bannerStickyContainers) {
    container.style.height = `${newHeight}px`;
  }
}

export function setAdnamiPlacement(placementId: PlacementId) {
  if (
    !activeAdnamiPlacementIds.some((placements) => placements === placementId)
  ) {
    activeAdnamiPlacementIds.push(placementId);
    setSkyscraperContainerHeight();
  }
}

export function initiateHandleStickySkyscrapers() {
  if (typeof ResizeObserver !== 'undefined') {
    let prevBodyHeight = document.body.offsetHeight;

    // Create and setup ResizeObserver to watch body height changes
    const resizeObserver = new ResizeObserver(() => {
      const currentBodyHeight = document.body.offsetHeight;

      if (currentBodyHeight !== prevBodyHeight) {
        prevBodyHeight = currentBodyHeight;
        setSkyscraperContainerHeight();
      }
    });

    // Start observing the document body
    resizeObserver.observe(document.body);
  }

  for (const placement of getPlacementList()) {
    listenPlacementKeysForValueChange(
      placement.placementId,
      'status',
      (status, oldStatus) => {
        if (status === 'loaded' && oldStatus !== 'loaded') {
          setSkyscraperContainerHeight();
        }
      }
    );
  }
}
