import { useState, useEffect } from 'react';

import type {
  ClientAdPlacement,
  PlacementId
} from '@schibsted-nmp/advertising-shared';
import { listenKeys } from '@podium/store';

import { $placementsMap } from '../atoms/placements.js';

export const useIsBannerLoaded = (placementId: PlacementId): boolean => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const checkIfLoaded = (placements: Record<string, ClientAdPlacement>) => {
      const placement = placements[placementId];
      if (placement) setLoaded(placement.status === 'loaded');
    };

    const unsubscribe = listenKeys(
      $placementsMap,
      [`${placementId}.status`],
      checkIfLoaded
    );

    checkIfLoaded($placementsMap.get());

    return () => {
      unsubscribe();
    };
  }, [placementId]);

  return loaded;
};
