import type { Brand } from '@schibsted-nmp/advertising-shared';
import type { Script } from '@client/core/utils/Script.js';

export namespace Scripts {
  export const Config = {
    bilbasen: null,
    blocket:
      'https://cdn.brandmetrics.com/tag/6de9037c-8539-4e57-9887-89a27070c085/blocket.js',
    dba: null,
    finn: 'https://cdn.brandmetrics.com/tag/1978245a-275b-4980-8918-4f1dc33badbf/finn.no.js',
    oikotie: null,
    rakentaja: null,
    tori: 'https://cdn.brandmetrics.com/tag/5b1cffc6-4c67-4602-82d7-a77c0645fca6/tori.fi.js'
  } as const satisfies Config;

  export type Config = Readonly<Record<Brand, Script.Url | null>>;

  export function* get(brand: Brand): Generator<Script, void, void> {
    const src = Config[brand];
    if (src) yield { name: 'brand-metrics', src };
  }
}
