import React from 'react';

type InsertBanners = {
  adItems: React.ReactNode[];
  bannerPositions?: number[];
  bannerElements?: React.ReactNode[];
};

export function insertBanners({
  adItems,
  bannerPositions,
  bannerElements
}: InsertBanners) {
  if (!bannerPositions || !bannerElements) {
    return adItems;
  }

  return adItems.flatMap((item, index) =>
    bannerPositions.includes(index)
      ? [bannerElements[bannerPositions.indexOf(index)], item]
      : [item]
  );
}
