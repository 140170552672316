import { debounce } from 'throttle-debounce';

import type { PlacementId } from '@schibsted-nmp/advertising-shared';

const DESKTOP_MIN_WIDTH = 1024; // typical desktop breakpoint
const RESIZE_DEBOUNCE_MS = 250;

/**
 * Observes an advertisement placement for full-width iframes.
 * This function monitors both existing and dynamically added iframes within the specified placement.
 * When an iframe's width approaches the window width (considering the margin), the callback is triggered
 * and the observation stops.
 *
 * @param placementId - The ID of the advertisement placement to observe
 * @param callback - Function to execute when a full-width iframe is detected
 * @param margin - Optional margin in pixels. The iframe is considered full-width when its width is >= (window width - margin).
 *                 Defaults to 100px
 *
 * @example
 * ```typescript
 * // Basic usage with default margin
 * observeFullWidthAd('topBanner', () => console.log('Full width detected'));
 *
 * // Usage with custom margin
 * observeFullWidthAd('topBanner', () => console.log('Full width detected'), 50);
 * ```
 *
 * @remarks
 * - Only triggers on desktop viewports (width >= 1024px)
 * - Automatically disconnects observers after the callback is executed
 * - Uses ResizeObserver to monitor iframe dimensions
 * - Uses MutationObserver to detect dynamically added iframes
 */
export const observeFullWidthAd = (
  placementId: PlacementId,
  callback: () => void,
  margin = 100
): void => {
  const placement = document.getElementById(placementId);
  if (!placement) return;

  const observer = new ResizeObserver(
    debounce(RESIZE_DEBOUNCE_MS, (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target instanceof HTMLIFrameElement) {
          const isDesktop = window.innerWidth >= DESKTOP_MIN_WIDTH;
          if (!isDesktop) return;

          const isFullWidth =
            entry.target.offsetWidth >= window.innerWidth - margin;

          if (!isFullWidth) return;

          callback();

          // Disconnect both observers
          observer.disconnect();
          mutationObserver.disconnect();
        }
      }
    })
  );

  const mutationObserver = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      for (const node of mutation.addedNodes) {
        if (node instanceof HTMLIFrameElement) observer.observe(node);
      }
    }
  });

  // Observe both existing and new iframes
  const iframes = placement.getElementsByTagName('iframe');

  for (const iframe of Array.from(iframes)) {
    observer.observe(iframe);
  }

  // Watch for new iframes
  mutationObserver.observe(placement, { childList: true, subtree: true });
};
