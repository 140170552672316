import { $config, $waitForClient } from '@client/core/atoms/config.js';
import {
  refreshKeywords,
  type FilterUpdatePayloadType
} from '@client/core/utils/refreshKeywords.js';
import type { Event } from '@podium/browser';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { debugLog, PageType } from '@schibsted-nmp/advertising-shared';

type TravelPageLoadedEvent = Event<{
  pageType: string;
  page?: number;

  destination?: {
    continent?: string;
    country?: string;
    city?: string;
  };

  destinasjon?: {
    continent?: string;
    country?: string;
    city?: string;
  };

  subvertical: string;
}>;

export function setupTravelEvents() {
  const { pageType } = $config.get() ?? {};

  if (pageType === PageType.Result) {
    messageBus.subscribe<FilterUpdatePayloadType>(
      events.SEARCH.channel,
      events.SEARCH.FILTER.topic,
      (event) => {
        refreshKeywords({
          // eslint-disable-next-line @typescript-eslint/no-misused-spread
          ...event,
          payload: { searchParams: event.payload }
        }).catch((error: unknown) => {
          debugLog('Error refreshing keywords', error);
        });
      }
    );
  }

  // Used by the travel vertical to set keywords on page load because they're not getting all the proper pageOpts
  const peekEvent: TravelPageLoadedEvent | undefined = messageBus.peek(
    'page',
    'loaded'
  );

  if (peekEvent) {
    travelLoadedCallback(peekEvent);
  } else {
    messageBus.subscribe('page', 'loaded', (event: TravelPageLoadedEvent) => {
      travelLoadedCallback(event);
    });
  }
}

function travelLoadedCallback(event: TravelPageLoadedEvent) {
  $waitForClient.set(true);
  debugLog('Travel page loaded event received', event);

  refreshKeywords({
    // eslint-disable-next-line @typescript-eslint/no-misused-spread
    ...event,
    payload: { searchParams: event.payload }
  })
    .then(() => {
      $waitForClient.set(false);
    })
    .catch((error: unknown) => {
      debugLog('Error refreshing keywords', error);
    });
}
