import { useEffect, useRef, useState } from 'react';

import type { ClientAdPlacement } from '@schibsted-nmp/advertising-shared';

import {
  $placementsMap,
  getPlacementList,
  placementHasIntermingle
} from '../atoms/placements.js';

export function useIntermingles(): ClientAdPlacement[] {
  const getInitialIntermingles = (): Array<ClientAdPlacement> => {
    if (typeof window === 'undefined') return [];
    return getPlacementList().filter(placementHasIntermingle);
  };

  const [intermingles, setIntermingles] = useState(getInitialIntermingles);
  const intermingleSub = useRef<() => void>();

  useEffect(() => {
    if (intermingles.length === 0) {
      intermingleSub.current = $placementsMap.subscribe(() => {
        const placementsWithIntermingle = getPlacementList().filter(
          placementHasIntermingle
        );

        setIntermingles(placementsWithIntermingle);
      });
    }

    return () => {
      intermingleSub.current?.();
    };
  }, []);

  return intermingles;
}
