import {
  getInitialState,
  hasInitialPlacements
} from '@client/core/utils/dom/getInitialState.js';
import {
  ConsentStatus,
  debugLog,
  type InitialState
} from '@schibsted-nmp/advertising-shared';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics.js';
import * as RelevantDigital from '@client/core/relevantDigital/index.js';

import { loadAndActivateI18n } from '../i18n.js';
import { initiateAdvt } from './initiate.js';
import { Scripts } from './Scripts.js';
import { setInitialGlobalAtoms } from './core/atoms/index.js';
import { TcfApiMock } from './TcfApiMock.js';
import { TcfHelperMock } from './TcfHelperMock.js';
import { addLifecycleEvent } from './core/atoms/metrics.js';
import { waitForConsent } from './core/utils/consent.js';

(async () => {
  try {
    const initialState = getInitialState();
    if (!initialState) return;

    if (initialState.env === 'local') {
      TcfApiMock.Window.inject();
      TcfHelperMock.Window.inject();
    }

    const consent = await waitForConsent();
    debugLog('Consent received: ', consent);
    setupGlobals(initialState, consent);
    logForLocalEnvironment(initialState);

    if (!hasInitialPlacements(initialState)) return;

    loadAndActivateI18n(initialState.locale);

    try {
      await Promise.all(Scripts.load(initialState, consent));
      await initiateAdvt(initialState);
    } catch (error) {
      debugLog('Failed to load scripts', error);
    }
  } catch (error) {
    debugLog('Something went wrong while loading advertising-podlet', error);

    if (error instanceof Error) {
      await sendErrorMessageMetricToServer(error.message);
    }
  }
})().catch((error: unknown) => {
  debugLog(
    'sendErrorMessageMetricToServer failed for advertising-podlet',
    error
  );
});

function logForLocalEnvironment(initialState: InitialState) {
  const { config, env } = initialState;
  if (env !== 'local') return;

  if (!config.placements.length) {
    addLifecycleEvent('NB! No advertising placements returned from server');
    debugLog('NB! No advertising placements returned from server');
  }
}

/**
 * Sets up some globals to manage ad config and related utilities.
 */
function setupGlobals(
  initialState: InitialState,
  consent: ConsentStatus
): void {
  RelevantDigital.GlobalJs.Window.inject();

  window.apntag ??= {} as NonNullable<Window['apntag']>;
  window.apntag['anq'] ??= [];

  setInitialGlobalAtoms(initialState, consent);
}
