import {
  GamMediaType,
  type GamSize,
  type SizeMediaType
} from './types/api/index.js';

export function* getSizesByMediaType(
  mediaTypes: ReadonlyArray<SizeMediaType>
): Generator<GamSize, void, unknown> {
  for (const { type, width, height } of mediaTypes) {
    switch (type) {
      case GamMediaType.Display:
      case GamMediaType.Video:
        if (width && height) yield [width, height];
        continue;

      case GamMediaType.Native:
        yield 'fluid';
        continue;

      default:
        continue;
    }
  }
}
