import {
  AdPlacement,
  debugLog,
  type GamKeyValue,
  type PlacementId
} from '@schibsted-nmp/advertising-shared';

import { $amplitudeExperimentsAtom } from '../../atoms/amplitude.js';
import { setGamTargetingAtom } from '../../atoms/gamTargeting.js';
import { addLifecycleEvent } from '../../atoms/metrics.js';

type ExperimentTargeting = {
  key: string;
  value: string;
};

// This will be accessed when setting up individual slots
const placementExperimentTargeting = new Map<
  PlacementId,
  ExperimentTargeting
>();

/**
 * Extracts the active Amplitude experiment and sends it to Google Ad Manager as a targeting key-value pair.
 * If the experiment payload contains a "placement" field, the targeting will only be applied to that specific placement.
 * Otherwise, it will be applied globally to all placements.
 */
export function sendAmplitudeExperimentToGam(): void {
  try {
    const { experiments, payloads } = $amplitudeExperimentsAtom.get();

    debugLog('Amplitude experiments payloads:', payloads);

    if (Object.keys(experiments).length === 0) {
      debugLog('No active Amplitude experiments found');
      return;
    }

    placementExperimentTargeting.clear();

    const globalExperiments = new Array<ExperimentTargeting>();

    for (const [experimentName, value] of Object.entries(experiments)) {
      if (!value) {
        debugLog(`Skipping experiment ${experimentName} with empty value`);
        continue;
      }

      const experimentPayload = payloads[experimentName];

      debugLog(`Processing experiment: ${experimentName} with value: ${value}`);
      debugLog(`Experiment payload:`, experimentPayload);

      // Check if the payload has a placement field
      const targetPlacement =
        experimentPayload &&
        typeof experimentPayload === 'object' &&
        'placement' in experimentPayload
          ? String(experimentPayload.placement)
          : undefined;

      // If there's a specific placement in the payload, store it for that placement:
      const placementIsInPayload =
        targetPlacement &&
        Object.values(AdPlacement)
          .map(({ id }) => id)
          .includes(targetPlacement as PlacementId);

      if (placementIsInPayload) {
        const placementId = targetPlacement as PlacementId;

        debugLog(
          `Storing experiment ${experimentName} for specific placement: ${placementId}`
        );

        placementExperimentTargeting.set(placementId, {
          key: 'experiment',
          value
        });

        addLifecycleEvent(
          `Amplitude experiment ${experimentName} prepared for placement ${placementId}`
        );
      } else {
        // No specific placement, add to global targeting
        debugLog(`Adding experiment ${experimentName} to global targeting`);
        globalExperiments.push({ key: 'experiment', value });
      }
    }

    // Apply global experiments to all placements
    if (globalExperiments.length > 0) {
      const gamTargeting = globalExperiments.map(
        ({ key, value }): GamKeyValue => ({ key, value: [value] })
      );

      debugLog(
        'Sending global Amplitude experiments to GAM targeting:',
        gamTargeting
      );

      setGamTargetingAtom(gamTargeting);
      addLifecycleEvent('Global Amplitude experiments sent to GAM targeting');
    }
  } catch (error) {
    console.error('Error sending Amplitude experiment to GAM:', error);
    if (error instanceof Error) {
      addLifecycleEvent(
        `Error sending Amplitude experiment to GAM: ${error.message}`
      );
    }
  }
}

export function getExperimentTargetingForPlacement(
  placementId: PlacementId
): ExperimentTargeting | undefined {
  return placementExperimentTargeting.get(placementId);
}

/**
 * Sets up a subscription to the Amplitude experiments atom to update GAM targeting
 * whenever experiments change.
 */
export function setupAmplitudeExperimentSubscription(): void {
  try {
    $amplitudeExperimentsAtom.subscribe(() => {
      debugLog('Amplitude experiments updated, updating GAM targeting');
      sendAmplitudeExperimentToGam();
    });

    debugLog('Amplitude experiment subscription set up successfully');
  } catch (error) {
    console.error('Error setting up Amplitude experiment subscription:', error);

    if (error instanceof Error) {
      addLifecycleEvent(
        `Error setting up Amplitude experiment subscription: ${error.message}`
      );
    }
  }
}
