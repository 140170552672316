import { atom, type WritableAtom } from '@podium/store';

export enum AtomChannels {
  Podlet = 'advertising-podlet',
  Companion = 'advertising-companion'
}

export enum AtomTopics {
  PlacementsMap = 'placementsMap',
  RecommendationAdItems = 'recommendationAdItems'
}

export const $advItemIds: WritableAtom<string[]> = atom<string[]>(
  AtomChannels.Companion,
  AtomTopics.RecommendationAdItems,
  []
);
