import React, { useState } from 'react';

import {
  ENABLED_DEBUG_PARAM,
  ENABLED_DEBUG_PARAM_PLACEMENT_ID
} from '@schibsted-nmp/advertising-shared';

function clearStorageForDebug() {
  window.localStorage.removeItem(ENABLED_DEBUG_PARAM);
  window.localStorage.removeItem(ENABLED_DEBUG_PARAM_PLACEMENT_ID);
}

export function ToolsTab() {
  const [message, setMessage] = useState<string>('');
  const [placementId, setPlacementId] = useState<string>('');

  const promptUserToOpenConsole = React.useCallback(() => {
    setMessage('Ran script. Open the console ✓');

    setTimeout(() => {
      setMessage('');
    }, 4000);
  }, [setMessage]);

  const onGamConsoleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      window.googletag?.openConsole();
    },
    []
  );

  const onAnalysisClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      window.ADVT_EVENTS?.analysis();
      promptUserToOpenConsole();
    },
    []
  );

  const onDebugLogsClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const url = new URL(window.location.href);
      setMessage('Enabled after the reload');
      clearStorageForDebug();

      setTimeout(() => {
        url.searchParams.set(ENABLED_DEBUG_PARAM, 'true');
        window.location.href = url.toString();
      }, 1000);
    },
    [setMessage]
  );

  const onDebugLogsPlacementIdClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (!placementId.trim()) {
        setMessage('Please enter a Placement ID');
        return;
      }

      const url = new URL(window.location.href);
      setMessage('Enabled after the reload');
      clearStorageForDebug();

      setTimeout(() => {
        url.searchParams.set(ENABLED_DEBUG_PARAM, 'true');
        url.searchParams.set(ENABLED_DEBUG_PARAM_PLACEMENT_ID, placementId);
        window.location.href = url.toString();
      }, 1000);
    },
    [placementId, setMessage]
  );

  const onPlacementIdChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlacementId(event.target.value);
    },
    [setPlacementId]
  );

  return (
    <div className="ml-8 mr-8 flex flex-col gap-4">
      {message && (
        <div className="flex justify-center bg-disabled p-16 m-16 rounded">
          {message}
        </div>
      )}

      <div className="flex flex-col gap-16">
        <button
          className="bg-primary text-inverted p-16 rounded border-t-warning border-r-info border-b-danger border-l-success"
          onClick={onGamConsoleClick}
        >
          Google publisher console
        </button>

        <button
          className="bg-primary text-inverted p-16 rounded"
          onClick={onAnalysisClick}
        >
          ADVT_EVENTS.analysis
        </button>

        <button
          className="bg-primary text-inverted p-16 rounded"
          onClick={onDebugLogsClick}
        >
          ADVT Debug logs
        </button>

        <button
          className="bg-primary text-inverted p-16 rounded"
          onClick={onDebugLogsPlacementIdClick}
        >
          Enable Debug for Placement ID
        </button>

        <input
          type="text"
          value={placementId}
          onChange={onPlacementIdChange}
          placeholder="Enter Placement ID"
          className="p-8 border border-gray-300 rounded"
        />
      </div>

      <div className="mt-16 text-sm">
        Other tools you might like would be{' '}
        <a
          href="https://chromewebstore.google.com/detail/professor-prebid/kdnllijdimhbledmfdbljampcdphcbdc?hl=en"
          className="text-link underline"
        >
          Professor Prebid
        </a>{' '}
        chrome extension
      </div>
    </div>
  );
}

ToolsTab.friendlyName = 'Tools' as const;
