/**
 * Converts all values in an object to string arrays. If a value is already an array, it remains unchanged.
 * @example
 * const input = { key: 'value', nums: 123, existing: ['already', 'array'] };
 * const result = objectValuesToArrays(input);
 * // Result: { key: ['value'], nums: ['123'], existing: ['already', 'array'] }
 */
export function objectValuesToStringArrays<
  T extends Partial<Record<string, unknown>>
>(object: T) {
  const entries = Object.entries(object);

  const mappedEntries = entries.map(
    ([key, value]) =>
      [key, Array.isArray(value) ? value.map(String) : [String(value)]] as const
  );

  return Object.fromEntries(
    mappedEntries
  ) as objectValuesToStringArrays.Result<T>;
}

export namespace objectValuesToStringArrays {
  export type Result<T extends Partial<Record<string, unknown>>> = {
    [K in keyof T]: Array<
      AsStringlikeOrString<
        Exclude<T[K] extends Array<infer V> | undefined ? V : T[K], undefined>
      >
    >;
  };

  type AsStringlikeOrString<T> = T extends string ? T : string;
}
