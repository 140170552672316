export function sizeIsNativeAd(
  size: googletag.events.SlotRenderEndedEvent['size']
) {
  return Array.isArray(size) && size[0] === 0 && size[1] === 0;
}

export function eventIsNativeCpmAd(
  event: googletag.events.SlotRenderEndedEvent & {
    // TODO: check up on this property, as it seems it should come from elsewhere, e.g. `googletag.ResponseInformation`:
    creativeTemplateId?: number;
  }
) {
  const nativeCpmCreativeTemplateIds = [12399765];

  return Boolean(
    event.creativeTemplateId &&
      nativeCpmCreativeTemplateIds.includes(event.creativeTemplateId)
  );
}

export function sizeIsFullScreenAd(
  size: googletag.events.SlotRenderEndedEvent['size']
) {
  return Array.isArray(size) && size[0] === 1920 && size[1] === 1080;
}

export function sizeIsAdnami(
  size: googletag.events.SlotRenderEndedEvent['size']
) {
  return Array.isArray(size) && size[0] === 1 && size[1] === 2;
}

export function sizeIsDbaDesktopIntermingle(
  size: googletag.events.SlotRenderEndedEvent['size']
) {
  return Array.isArray(size) && size[0] === 930 && size[1] === 180;
}

export function sizeExceedsCenterContentWidth(
  size: googletag.events.SlotRenderEndedEvent['size']
) {
  const CENTER_CONTENT_WIDTH_LIMIT = 620; // TODO: temp threshold, should be verified with design team
  return Array.isArray(size) && (size[0] ?? 0) > CENTER_CONTENT_WIDTH_LIMIT;
}
