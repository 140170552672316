import React, { forwardRef } from 'react';

import type {
  LayoutType,
  PlacementId,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';

export type Props = {
  placementId: PlacementId;
  containerId?: string;
  adIndex?: number;
  categories?: RecommendationCategories;
  initialLayoutType?: LayoutType;
};

export const AdvertisingWebComponent = forwardRef<HTMLDivElement, Props>(
  (
    { placementId, adIndex, initialLayoutType, categories, containerId }: Props,
    ref
  ) => (
    <advt-component
      containerId={containerId}
      initialLayoutType={initialLayoutType}
      categories={categories}
      adIndex={adIndex}
      placementId={placementId}
    >
      <div
        ref={ref}
        id={`${placementId}--container`}
        slot={`${placementId}--slot`}
      ></div>
    </advt-component>
  )
);
