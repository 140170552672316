import React from 'react';

import { createRoot } from 'react-dom/client';

import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { $placementsMap } from '@client/core/atoms/placements.js';
import {
  type AdnPlacement,
  AdServer,
  type ClientAdPlacement,
  type PlacementId,
  PlacementStatus
} from '@schibsted-nmp/advertising-shared';
import AdnAdUnit from '@client/adnuntius/AdUnit/AdnAdUnit.js';
import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId.js';

export function renderAdnAd(props: {
  placementId: PlacementId;
  containerId: string;
}) {
  const element = document.getElementById(props.containerId);
  const placementElement = element && createRoot(element);
  const inViewSettings = getInViewSettingsByBrand(props.placementId);

  if (placementElement) {
    const placements = $placementsMap.get();

    if (Object.keys(placements).length === 0) {
      console.error(`No placements found`);
      updateMetricByKey(props.placementId, PlacementStatus.NotInConfig);

      return;
    }

    const placement = placements[props.placementId];

    if (!placement) {
      console.error(`PlacementId: ${props.placementId} not found`);
      return;
    }

    if (placement.adServer.type !== AdServer.Adn) {
      console.error(
        `PlacementId: ${props.placementId} is not of ad server type '${AdServer.Adn}'`
      );

      return;
    }

    const adnPlacement = placement as ClientAdPlacement<AdnPlacement>;

    placementElement.render(
      <AdnAdUnit
        containerId={props.containerId}
        placement={adnPlacement}
        inViewSettings={inViewSettings}
      />
    );
  }
}
