import { getPersistedGlimrTags } from '@client/core/utils/glimr.js';
import { PPID_STORAGE_NAME } from '@client/core/utils/pulse/pulse.js';
import { atom } from '@podium/store';
import {
  type AdConfig,
  type InitialState,
  type PlacementId,
  type PPIDResponse,
  type SearchParams
} from '@schibsted-nmp/advertising-shared';

import localStorage from '../utils/localStorage.js';

import { AtomChannels, AtomTopics } from './index.js';

//
// ATOMS AND TYPES
//
export const $config = atom<$config.Value>(
  AtomChannels.Podlet,
  AtomTopics.Config,
  null
);

export namespace $config {
  export type Value = AdConfig | null;
}

export const $shouldPreloadPlacementIds =
  atom<$shouldPreloadPlacementIds.Value>(
    AtomChannels.Podlet,
    AtomTopics.PrebidLoadedList,
    []
  );

export namespace $shouldPreloadPlacementIds {
  export type Value = Array<PlacementId>;
}

export const $ppidAtom = atom<$ppidAtom.Value>(
  AtomChannels.Podlet,
  AtomTopics.PPID,
  getEmptyOrPersistedPPID()
);

export namespace $ppidAtom {
  export type Value = PPIDResponse;
}

export const $waitForClient = atom<$waitForClient.Value>(
  AtomChannels.Podlet,
  AtomTopics.WaitForClient,
  false
);

export namespace $waitForClient {
  export type Value = boolean;
}

export const $glimrTagsAtom = atom<$glimrTagsAtom.Value>(
  AtomChannels.Podlet,
  AtomTopics.Glimr,
  getInitialPersistedGlimr()
);

export namespace $glimrTagsAtom {
  export type Value = Array<string>;
}

type SearchFilters = {
  previous: SearchParams;
  current: SearchParams;
};

export const $searchFilters = atom<SearchFilters>(
  AtomChannels.Podlet,
  AtomTopics.SearchFilters,
  {
    previous: {},
    current: {}
  } as SearchFilters
);

export const $initialState = atom<$initialState.Value>(
  AtomChannels.Podlet,
  AtomTopics.InitialState,
  {} as InitialState
);

export namespace $initialState {
  export type Value = InitialState;
}

export const emptyPpId = {
  delta: {
    ppId1: '',
    ppId2: '',
    adId: ''
  }
};

export function getEmptyOrPersistedPPID(): PPIDResponse {
  return (
    (localStorage.getItem(PPID_STORAGE_NAME) as PPIDResponse | undefined) ??
    emptyPpId
  );
}

export function getInitialPersistedGlimr() {
  return getPersistedGlimrTags();
}
