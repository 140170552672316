import type { GamSizeMapping } from '@schibsted-nmp/advertising-shared';

export const applySizeMapping = (
  slot: googletag.Slot,
  sizeMappings: ReadonlyArray<GamSizeMapping>,
  googletag: googletag.Googletag
): void => {
  if (sizeMappings.length === 0) return;

  const mapping = googletag.sizeMapping();

  // Sort size mappings by viewport size (largest first)
  const sortedMappings = [...sizeMappings].sort((a, b) => {
    const [widthA = 0] = a.breakpoint;
    const [widthB = 0] = b.breakpoint;
    return Number(widthB) - Number(widthA);
  });

  // Add each size mapping to the builder
  for (const sizeMap of sortedMappings) {
    mapping.addSize(sizeMap.breakpoint, sizeMap.sizes);
  }

  // Check if [0,0] breakpoint already exists in mappings
  const hasZeroBreakpoint = sortedMappings.some(
    (mapping) => mapping.breakpoint[0] === 0 && mapping.breakpoint[1] === 0
  );

  // Only add [0,0] fallback if it's not already defined
  if (!hasZeroBreakpoint) {
    // Add a fallback for when no breakpoints match (empty array)
    mapping.addSize([0, 0], []);
  }

  // Apply the size mapping to the slot
  slot.defineSizeMapping(mapping.build());
};
