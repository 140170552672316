import { UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';

import { Helpers } from './Helpers.js';
import type { Options } from './Options.js';
import { Settings } from './Settings.js';
import { Api } from './Api.js';

export namespace ConfigId {
  export function get(options?: Options): string | null {
    if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) return null;

    const parameters = Helpers.createParameters(options);
    if (!parameters) return null;

    const { brandConfig, path } = parameters;

    return Settings.get('configId', brandConfig, path) ?? null;
  }

  export async function exists(configId: string) {
    if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) return false;

    const configs = await Api.execute((api) => api.getConfigs());

    return configs.some((config) => config.configId === configId);
  }
}
