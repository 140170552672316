export const fetchElement = (query: string): HTMLElement | null =>
  document.querySelector(query);

export const fetchElementById = (id: string) => document.getElementById(id);

export const fetchAllElements = (query: string): NodeListOf<HTMLElement> =>
  document.querySelectorAll(query);

export const querySelect = (query: string): HTMLElement | null =>
  document.querySelector(query);

export const getDocumentHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );

export function styleElements(
  elements: HTMLElement | NodeListOf<HTMLElement> | HTMLElement[],
  styleObject: Partial<CSSStyleDeclaration>
): void {
  const elementArray =
    elements instanceof HTMLElement ? [elements] : Array.from(elements);

  for (const element of elementArray) {
    Object.assign(element.style, styleObject);
  }
}
