import React from 'react';

import { createRoot } from 'react-dom/client';

import { DevPanel } from '@client/dev/DevPanel.js';

export function renderDevPanel(divRoot: Element) {
  const root = createRoot(divRoot);
  root.render(<DevPanel />);
}
