import React from 'react';

import type {
  LayoutType,
  PlacementId,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';

export type Props = {
  placementId: PlacementId;
  containerId?: string;
  adIndex?: number;
  categories?: RecommendationCategories;
  initialLayoutType?: LayoutType;
};

/**
 * This component is used to render an advertising web component, only on the client-side
 * to help with hydration issues.
 */
export const AdvertisingClientWebComponent = React.forwardRef<
  HTMLDivElement,
  Props
>(
  (
    { placementId, adIndex, initialLayoutType, categories, containerId }: Props,
    ref
  ) => {
    const [clientSide, setClientSide] = React.useState(false);

    React.useEffect(() => {
      setClientSide(true);
    }, []);

    if (!clientSide) return null;

    return (
      <advt-component
        containerId={containerId}
        initialLayoutType={initialLayoutType}
        categories={categories}
        adIndex={adIndex}
        placementId={placementId}
      >
        <div
          ref={ref}
          id={`${placementId}--container`}
          slot={`${placementId}--slot`}
        ></div>
      </advt-component>
    );
  }
);
