import React from 'react';

import type { PlacementId } from '@schibsted-nmp/advertising-shared';

export function AdvertisingAfsWebComponent({
  placementId
}: AdvertisingAfsWebComponent.Props) {
  return <advt-component placementId={placementId} />;
}

export namespace AdvertisingAfsWebComponent {
  export type Props = {
    placementId: PlacementId;
  };
}
