import { atom } from '@podium/store';

import { AtomChannels, AtomTopics } from './index.js';

type WallpaperConfig = {
  clickThroughUrl: string;
  wallpaperImage: string | null;
  wallpaperHtml: string | null;
  stickyWallpaper: boolean;
  stickySkyscrapers: boolean;
};

export const $wallpaperConfig = atom<WallpaperConfig>(
  AtomChannels.Podlet,
  AtomTopics.Cycle,
  {
    clickThroughUrl: '',
    wallpaperImage: '',
    wallpaperHtml: '',
    stickySkyscrapers: true,
    stickyWallpaper: false
  }
);

export namespace $wallpaperConfig {
  export type Value = WallpaperConfig;
}
