import type { TemplateResult } from 'lit';

import type { AdServer, PlacementId } from '@schibsted-nmp/advertising-shared';

/**
 * AdVendor is an abstract class that represents an ad vendor
 * It has a setupEvents method that sets up event listeners
 * It has a cleanupEvents method that removes event listeners
 * It has a requestAd method that requests an ad
 * It has a render method that returns a TemplateResult
 * Specific ad vendors extend this class and implement these methods
 */
export abstract class AdVendor {
  adServer: AdServer | null;

  containerId: string;

  placementId: PlacementId;

  constructor(
    adServer: AdServer | null,
    placementId: PlacementId,
    containerId: string
  ) {
    this.adServer = adServer;
    this.placementId = placementId;
    this.containerId = containerId;
  }

  // Use the interface in the abstract method
  abstract setupEvents(): void;

  abstract cleanupEvents(): void;

  abstract requestAd(): void;

  abstract updated(
    changedProperties: Map<string | number | symbol, unknown>
  ): void;

  abstract render(): TemplateResult;
}
