import type { FromPath } from 'nanostores';

import { deepMap, listenKeys } from '@podium/store';
import type {
  AdStatus,
  ClientAdPlacement,
  PlacementId
} from '@schibsted-nmp/advertising-shared';
import { placementHasIntermingle } from '@schibsted-nmp/advertising-companion';

import { AtomChannels, AtomTopics } from './index.js';

export const $placementsMap = deepMap<$placementsMap.Value>(
  AtomChannels.Podlet,
  AtomTopics.PlacementsMap,
  {}
);

export namespace $placementsMap {
  export type Value = Record<string, ClientAdPlacement>;
}

export const $statusSequence = deepMap<$statusSequence.Value>(
  AtomChannels.Podlet,
  AtomTopics.AdStatusSequence,
  {}
);

export namespace $statusSequence {
  export type Value = Record<string, Array<AdStatus>>;
}

export function updatePlacementKeyValueById<
  TKey extends keyof ClientAdPlacement
>(
  placementId: PlacementId,
  updateKey: TKey,
  updateValue: FromPath<$placementsMap.Value, `${PlacementId}.${TKey}`>
) {
  const currentPlacement = $placementsMap.get()[placementId];

  const hasSameValue =
    currentPlacement && currentPlacement[updateKey] === updateValue;

  if (!currentPlacement || hasSameValue) return;

  $placementsMap.setKey(`${placementId}.${updateKey}`, updateValue);
}

export function listenPlacementKeysForValueChange<
  K extends keyof ClientAdPlacement
>(
  placementId: PlacementId,
  updateKey: K,
  callback: (
    value: ClientAdPlacement[K],
    oldValue?: ClientAdPlacement[K]
  ) => void
) {
  return listenKeys(
    $placementsMap,
    [`${placementId}.${updateKey}`],
    (placements, oldPlacements) => {
      // TODO: fix non-null assertion:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const placement = placements[placementId]!;
      const oldPlacement = oldPlacements[placementId];
      const value = placement[updateKey];
      const oldValue = oldPlacement?.[updateKey];
      callback(value, oldValue);
    }
  );
}

export function addStatusToSequence(
  placementId: PlacementId,
  status: AdStatus
) {
  const currentSequence = $statusSequence.get()[placementId] ?? [];
  $statusSequence.setKey(placementId, [...currentSequence, status]);
}

export function getPlacementList(): Array<ClientAdPlacement> {
  return Object.values($placementsMap.get()).filter(({ placementId }) =>
    Boolean(placementId)
  );
}

export function getPlacementById(
  placementId: PlacementId
): ClientAdPlacement | undefined {
  return $placementsMap.get()[placementId];
}

function updatePlacementsStatus(
  placements: Array<ClientAdPlacement>,
  status: AdStatus
): Record<string, ClientAdPlacement> {
  return placements.reduce<Record<string, ClientAdPlacement>>(
    (acc, placement) => {
      acc[placement.placementId] = { ...placement, status };
      return acc;
    },
    {}
  );
}

export function getRequestPlacements() {
  return getPlacementList().filter(
    (placement) => placement.status === 'request'
  );
}

export function getInterminglePlacements() {
  return getPlacementList().filter(placementHasIntermingle);
}

export function updateAllPlacementStatuses(status: AdStatus) {
  const allPlacements = getPlacementList();
  const updatedPlacements = updatePlacementsStatus(allPlacements, status);
  $placementsMap.set(updatedPlacements);
}

export function updatePlacementStatuses(
  placementList: Array<ClientAdPlacement>,
  status: AdStatus
) {
  for (const placement of placementList) {
    $placementsMap.setKey(`${placement.placementId}.status`, status);
  }
}
