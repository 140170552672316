import React from 'react';

import classNames from 'classnames';

export type Tab<T extends object = object> = React.ComponentType<T> & {
  readonly friendlyName: string;
};

export function Tab<T extends Tab>({
  Component,
  selected,
  onSelect
}: Tab.Props<T>) {
  const className = React.useMemo(
    () =>
      classNames('p-4 text-xs', {
        's-text-subtle s-bg-hover': !selected,
        's-text-link s-bg font-bold': selected
      }),
    [selected]
  );

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onSelect(Component);
    },
    [Component, onSelect]
  );

  return (
    <button className={className} style={Tab.style} onClick={onClick}>
      {Component.friendlyName}
    </button>
  );
}

export namespace Tab {
  export type Props<T extends Tab> = {
    readonly Component: T;
    readonly selected: boolean;
    onSelect(this: void, Component: T): void;
  };

  export const style = {
    borderRadius: '8px 8px 0 0',
    transition:
      'background-color 0.2s ease, transform 0.2s ease, width 0.2s ease-in-out'
  } as const satisfies React.CSSProperties;

  export function Group<T extends Tab>({
    TabComponents,
    SelectedTabComponent,
    onSelect
  }: Group.Props<T>) {
    return (
      <div className="w-full overflow-auto">
        <div className="dev-panel__tabs flex gap-4 s-bg-selected ml-8 mr-8 mb-16">
          {TabComponents.map((TabComponent) => (
            <Tab
              key={TabComponent.friendlyName}
              Component={TabComponent}
              selected={TabComponent === SelectedTabComponent}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    );
  }

  export namespace Group {
    export type Props<T extends Tab> = {
      readonly TabComponents: ReadonlyArray<T>;
      readonly SelectedTabComponent: T;
      onSelect(this: void, TabComponent: T): void;
    };
  }
}
