import { $gamTargetingAtom } from '@client/core/atoms/gamTargeting.js';
import { addLifecycleEvent } from '@client/core/atoms/metrics.js';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures.js';
import {
  debugLog,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { ConfigId } from './ConfigId.js';
import { Api } from './Api.js';

export namespace Slots {
  /**
   * Starts the Relevant Digital Prebid auction for the given slots, if present.
   * Returns a promise that resolves with the slots to be refreshed, or `null`
   * in case of an error or unmet condition. Even so, the slots may still be
   * refreshed afterwards - they'll just be lacking header bidding targeting.
   */
  export async function load(
    slots: ReadonlyArray<googletag.Slot | null | undefined>,
    ...abortSignals: ReadonlyArray<AbortSignal>
  ): Promise<ReadonlyArray<googletag.Slot> | null> {
    if (!isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) return null;
    if (typeof window === 'undefined') return null;

    const configId = ConfigId.get();
    if (!configId) return null;

    if (!(await ConfigId.exists(configId))) {
      debugLog(
        'Config ID not found in Relevant Digital Prebid script',
        configId
      );
      return null;
    }

    // Filter out invalid slots and get their IDs:
    const allowedDivIds = slots
      .filter(
        (slot): slot is NonNullable<typeof slot> =>
          slot !== null &&
          slot !== undefined &&
          typeof slot.getSlotElementId === 'function'
      )
      .map((slot) => slot.getSlotElementId());

    // Don't proceed if we have no valid slots:
    if (allowedDivIds.length === 0) {
      debugLog('No valid slots found for Relevant Digital Prebid');
      return null;
    }

    // At the moment this part of the code takes into account only mappings related to Tori
    // we can check at later stage as we implement other brands how to properly
    // separate mapping concerns if need be
    const gamTargeting = $gamTargetingAtom.get();

    const gamToXandrMapping: Partial<Record<string, string>> = {
      recom_cat_1: 'nmp-recommerce-category_level_1',
      recom_cat_2: 'nmp-recommerce-category_level_2',
      recom_cat_3: 'nmp-recommerce-category_level_3'
    };

    // Create a new object with keys replaced where applicable:
    const transformedTargeting = Object.fromEntries(
      gamTargeting.map(({ key, value }) => [
        gamToXandrMapping[key] ?? key,
        value
      ])
    );

    await Api.execute(
      (api) => {
        api.addPrebidConfig({
          appnexusAuctionKeywords: transformedTargeting
        });
      },
      ...abortSignals
    );

    addLifecycleEvent(
      `Loading Relevant Digital Prebid for GAM: ${allowedDivIds.join(', ')}`
    );

    debugLog('Loading Relevant Digital Prebid for GAM', allowedDivIds);

    const { promise, resolve, reject } =
      Promise.withResolvers<ReadonlyArray<googletag.Slot> | null>();

    for (const abortSignal of abortSignals) {
      abortSignal.addEventListener('abort', reject);
    }

    // Failsafe in case RY stalls somehow (this should never happen):
    const timeout = window.setTimeout(() => {
      reject(new Error('Did not call googletag.refresh() within time limit'));
    }, 5000);

    try {
      await Api.execute(
        (api) => {
          debugLog(
            'Executing Relevant Digital Prebid for GAM',
            allowedDivIds,
            allowedDivIds.map((allowedDivId) =>
              document.getElementById(allowedDivId)
            )
          );

          api.loadPrebid({
            configId,
            manageAdserver: false,
            collapseEmptyDivs: true,
            noGpt: true,
            collapseBeforeAdFetch: false,
            noSlotReload: false,
            allowedDivIds,

            googletagCalls: {
              refresh(slots) {
                /*
                  By not forwarding this call to `googletag.pubads().refresh()`,
                  we enable ourselves instead of Relevant Yield to handle the
                  refresh when we please.
                */
                resolve(slots ?? null);
              }
            },

            onBeforeAdRequest(parameters) {
              try {
                const requiredEventType = 'auctionInit';
                const { pbjs } = parameters.auction;
                const events = pbjs.getEvents();

                if (!events.length) {
                  throw new Error('No events found in Prebid.js event log');
                }

                const hasAuctionInit = events.some(
                  ({ eventType }) => eventType === requiredEventType
                );

                if (!hasAuctionInit) {
                  throw new Error(
                    `Missing required event: '${requiredEventType}'`
                  );
                }
              } catch (error) {
                reject(error);
              }
            }
          });
        },
        ...abortSignals
      );

      return await promise;
    } catch (error) {
      debugLog('Failed to execute Relevant Digital Prebid for GAM', error);
    } finally {
      for (const abortSignal of abortSignals) {
        abortSignal.removeEventListener('abort', reject);
      }

      window.clearTimeout(timeout);
    }

    return null;
  }
}
