// We can disable the eslint rule for this file because we are extending the types:
/* eslint-disable @typescript-eslint/consistent-type-definitions */

declare module './api.autogenerated.js' {
  export interface AdConfig {
    searchKey?: SearchKey | undefined;
  }

  export interface GamPlacement {
    overrideAdServer?: AdServer;
  }
}

export {};
