const win = typeof window !== 'undefined' ? window : ({} as Window);

// localStorage access throws exceptions in Android Chrome when cookies
// are disabled and when in private mode in Safari, hence all try/catch blocks
const hasStorageCapability = (() => {
  try {
    const test = 'test';
    win.localStorage.setItem(test, test);
    win.localStorage.removeItem(test);

    return true;
  } catch {
    return false;
  }
})();

function isTSExpired(timestamp: number, maxAge: number) {
  const nowMS = new Date().getTime();

  return timestamp + maxAge < nowMS;
}

function persistValue(key: string, value: unknown, timestamp?: number) {
  if (hasStorageCapability && value !== undefined && value !== null) {
    const persistMe = {
      data: value,
      timestamp: timestamp ?? new Date().getTime()
    };

    win.localStorage.setItem(key, JSON.stringify(persistMe));

    return true;
  }

  return false;
}

function clearValue(key: string) {
  if (hasStorageCapability) {
    win.localStorage.removeItem(key);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
function _getParsedValue<T>(key: string): T | null {
  const storedValue = win.localStorage.getItem(key);
  if (!storedValue) return null;

  try {
    return JSON.parse(storedValue) as T;
  } catch {
    console.log(
      `Unable to parse stored value for key ${key}. Clearing value.`,
      storedValue
    );

    clearValue(key);
  }

  return null;
}

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

function readValue(key: string, maxAgeMillis = FIVE_MINUTES_IN_MS) {
  if (!hasStorageCapability) {
    return null;
  }

  const persisted = _getParsedValue<{
    data?: unknown;
    timestamp?: number;
  }>(key);

  if (persisted?.data) {
    if (persisted.timestamp) {
      return isTSExpired(persisted.timestamp, maxAgeMillis)
        ? null
        : persisted.data;
    }

    return persisted.data;
  }

  return null;
}

const setItem = (key: string, item: unknown) => {
  if (hasStorageCapability) {
    win.localStorage.setItem(key, JSON.stringify(item));
  }
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const getItem = <T>(item: string): T | null => {
  const retrievedItem = window.localStorage.getItem(item);

  if (hasStorageCapability && retrievedItem) {
    return JSON.parse(retrievedItem) as T;
  }

  return null;
};

const localStorage: {
  readValue: (key: string, maxAgeMillis?: number) => unknown;
  setItem: (key: string, item: unknown) => void;
  getItem: (item: string) => unknown;
  persistValue: (key: string, value: unknown, timestamp?: number) => boolean;
  clearValue: (key: string) => void;
  hasStorageCapability: boolean;
} = {
  persistValue,
  readValue,
  clearValue,
  hasStorageCapability,
  setItem,
  getItem
};

export default localStorage;
