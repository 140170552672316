import {
  Brand,
  ConsentStatus,
  DeviceType,
  FinnLegendaryVertical,
  Vertical
} from '@schibsted-nmp/advertising-shared';

import type { Settings } from './Settings.js';

const Config: Config = {
  [Brand.Dba]: {
    accountId: 'schibsteddk',

    [Vertical.Recommerce]: {
      [DeviceType.Desktop]: {
        tagName: '6746e535ae5f9061736e078f',
        [ConsentStatus.Accepted]: { configId: '675ca048d76e720181faa8a4' },
        [ConsentStatus.Rejected]: { configId: '675ca0cb77987adc10faa8a6' }
      },

      [DeviceType.Mobile]: {
        tagName: '6746e535ae5f9061736e081b',
        [ConsentStatus.Accepted]: { configId: '677e9c590dbe305a5b5d1be5' },
        [ConsentStatus.Rejected]: { configId: '677e9c6602dcc4ef205d1be6' }
      }
    },

    [Vertical.Front]: {
      [DeviceType.Desktop]: {
        tagName: '679208bba0178c7a8ea0949b',
        [ConsentStatus.Accepted]: { configId: '679a04aa6fa3e204c7aa7f00' },
        [ConsentStatus.Rejected]: { configId: '679c88b675204ed72e005022' }
      },

      [DeviceType.Mobile]: {
        tagName: '679208dcaec9829236a0949c',
        [ConsentStatus.Accepted]: { configId: '679a04fab7cb1179e1aa7f02' },
        [ConsentStatus.Rejected]: { configId: '679c88f2db50fa327e005024' }
      }
    },

    [Vertical.Mobility]: {
      [DeviceType.Desktop]: {
        tagName: '6792087e1371569029a09496',
        [ConsentStatus.Accepted]: { configId: '6798b5e318001e8244f31580' },
        [ConsentStatus.Rejected]: { configId: '679c87e78e2714c7c900501e' }
      },

      [DeviceType.Mobile]: {
        tagName: '6792089bf7ec0f9863a09498',
        [ConsentStatus.Accepted]: { configId: '679a19cab4b5320181473041' },
        [ConsentStatus.Rejected]: { configId: '679c886d2969b9c2ef005020' }
      }
    },

    [FinnLegendaryVertical.Car]: {
      [DeviceType.Desktop]: {
        tagName: '6792087e1371569029a09496',
        [ConsentStatus.Accepted]: { configId: '6798b5e318001e8244f31580' },
        [ConsentStatus.Rejected]: { configId: '679c87e78e2714c7c900501e' }
      },

      [DeviceType.Mobile]: {
        tagName: '6792089bf7ec0f9863a09498',
        [ConsentStatus.Accepted]: { configId: '679a19cab4b5320181473041' },
        [ConsentStatus.Rejected]: { configId: '679c886d2969b9c2ef005020' }
      }
    }
  },

  [Brand.Finn]: {
    accountId: 'schibstedno',
    tagName: '674841ca854373f8fc57d8d9',
    [DeviceType.Desktop]: { configId: '674841d45b817321ce57d8da' },
    [DeviceType.Mobile]: { configId: '67616e2ec4a67ddfe0845fa8' }
  },

  [Brand.Tori]: {
    accountId: 'tori',

    [DeviceType.Desktop]: {
      tagName: '66cecb0e1a3b9040b52acc5f',
      configId: '66d5ac2aed0ef9bb84ce3fe9'
    },

    [DeviceType.Mobile]: {
      tagName: '66cecb0e1a3b9040b52acd7c',
      configId: '66dea8dd13855042b642272c'
    }
  }
};

type Config = Readonly<Partial<Record<Brand, Config.BrandSpecific>>>;

namespace Config {
  export type BrandSpecific = VerticalSpecific &
    Readonly<
      Partial<Record<Vertical | FinnLegendaryVertical, VerticalSpecific>>
    >;

  export type VerticalSpecific = ConsentSpecific &
    Readonly<Partial<Record<DeviceType, ConsentSpecific>>>;

  export type ConsentSpecific = Settings &
    Readonly<Partial<Record<ConsentStatus, Settings>>>;
}

export { Config };
