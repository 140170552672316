// Add your new Unleash Keys here without the 'advertising-podlet.' prefix
const UNLEASH_FEATURE_NAMES = [
  'killswitch',
  'disableAfsPlacements',
  'enableGam',
  'enableGamTestCampaign',
  'enableAdnTestCampaign',
  'enableAdnuntius',
  'disableAdvertisingConfigApi',
  'enableGamPrebid',
  'enableBrandMetricsScript',
  'enableGamLazyLoading',
  'overrideAfsPubId',
  'enableInhouseHighImpactBanners'
] as const;

export type UnleashFeatureName = (typeof UNLEASH_FEATURE_NAMES)[number];

export function getUnleashFeatureWithPodletPrefix(
  nameId: UnleashFeatureName
): string {
  return `advertising-podlet.${nameId}`;
}

export type UnleashKeys = (typeof UNLEASH_FEATURE_NAMES)[number];

type UnleashKeyObject = { [K in UnleashKeys]: K };

export const UNLEASH_FEATURE_NAME = UNLEASH_FEATURE_NAMES.reduce(
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  <TKey extends UnleashKeys>(acc: UnleashKeyObject, key: TKey) => {
    (acc[key] as TKey) = key;
    return acc;
  },
  {} as UnleashKeyObject
);

export const UNLEASH_FEATURE_ARRAY: Array<UnleashKeys> =
  Object.values(UNLEASH_FEATURE_NAME);

export type UnleashFeatureStatuses = {
  [K in UnleashFeatureName]?: boolean;
};
