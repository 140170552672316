import { atom } from '@podium/store';
import {
  UnleashFeatureManager,
  type Features,
  type UnleashFeatureName,
  type UnleashFeatureStatuses
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $unleashFeaturesAtom = atom<$unleashFeaturesAtom.Value>(
  AtomChannels.Podlet,
  AtomTopics.UnleashFeatures,
  {}
);

export namespace $unleashFeaturesAtom {
  export type Value = Features;
}

let unleashFeatureManager: UnleashFeatureManager | null = null;

export function isFeatureEnabled(featureKey: UnleashFeatureName) {
  if (unleashFeatureManager) {
    return unleashFeatureManager.isEnabled(featureKey);
  }

  const features = $unleashFeaturesAtom.get();
  unleashFeatureManager = new UnleashFeatureManager(features);

  return unleashFeatureManager.isEnabled(featureKey);
}

export function getAllFeatureStatuses(): UnleashFeatureStatuses {
  return unleashFeatureManager?.getAllFeatureStatuses() ?? {};
}
