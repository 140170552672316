import React from 'react';

import {
  type AdConfigPlacement,
  AdServer,
  type LayoutType,
  type PlacementId
} from '@schibsted-nmp/advertising-shared';

export type PositionData = {
  position: number;
  placementId: PlacementId;
  containerId: string;
  adServer: AdServer;
};

export type UseSearchPositionInstruction = {
  layoutType: LayoutType;
  interminglePlacements: Array<AdConfigPlacement>;
};

export function useSearchPositionInstruction({
  layoutType,
  interminglePlacements
}: UseSearchPositionInstruction) {
  return React.useMemo(
    () =>
      interminglePlacements
        .map<PositionData | null>(({ placementId, intermingle, adServer }) => {
          const interval = intermingle?.[layoutType];
          if (!interval) return null;

          return {
            containerId: `${placementId}--container`,
            placementId,
            position: interval,
            adServer: adServer.type as AdServer
          };
        })
        .filter((positionData) => positionData !== null),
    [layoutType, interminglePlacements]
  );
}
