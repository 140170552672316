import { debounce } from 'throttle-debounce';

import { $waitForClient } from '@client/core/atoms/config.js';
import { addLifecycleEvent } from '@client/core/atoms/metrics.js';
import { debouncedSendMetrics } from '@client/core/services/metrics.js';
import { AdPlacement, type AdnAdUnit } from '@schibsted-nmp/advertising-shared';
import { setupAdnEventListeners } from '@client/adnuntius/AdUnit/setupAdnEventListeners.js';

let pendingSlots: AdnAdUnit[] = [];
let delayedPendingSlots: AdnAdUnit[] = [];

// delay left 1 and right 1 with 200ms, becuase when sent as batch request with top 1 it does not trigger adnami topscroll regularly
const delayedBatchPlacements: string[] = [
  AdPlacement.AdvtLeft1.id,
  AdPlacement.AdvtRight1.id
];

let subscribedToClient = false;

function loadBatchedAllSlots(adUnits: Array<AdnAdUnit>) {
  window.adn.calls.push(() => {
    window.adn.request({
      adUnits
    });
  });

  setupAdnEventListeners(adUnits);
}

// Debounce function that accumulates slots and refreshes them after 200ms
const debounceLoadAd = debounce(
  200,
  () => {
    const slotsToLoad = [...pendingSlots];
    pendingSlots = [];

    loadBatchedAllSlots(slotsToLoad);

    addLifecycleEvent(
      `ADN Load batch of Ads for ${slotsToLoad.map((au) => au.targetId).join(', ')}`
    );

    debouncedSendMetrics();
  },
  { atBegin: false }
);

// Debounce function that accumulates slots and refreshes them after 400ms
const delayedDebounceLoadAd = debounce(
  400,
  () => {
    const slotsToLoad = [...delayedPendingSlots];
    delayedPendingSlots = [];

    loadBatchedAllSlots(slotsToLoad);

    addLifecycleEvent(
      `ADN Load batch of Ads for ${slotsToLoad.map((au) => au.targetId).join(', ')}`
    );

    debouncedSendMetrics();
  },
  { atBegin: false }
);

export function debouncedLoadAstTags(adUnit: AdnAdUnit) {
  const shouldDelay = delayedBatchPlacements.includes(adUnit.targetId);

  if (shouldDelay) {
    delayedPendingSlots.push(adUnit);
  } else {
    pendingSlots.push(adUnit);
  }

  const triggerDebounce = () => {
    if (shouldDelay) {
      delayedDebounceLoadAd();
      return;
    }
    debounceLoadAd();
  };

  const waitForClient = $waitForClient.get();
  if (waitForClient && !subscribedToClient) {
    subscribedToClient = true;
    $waitForClient.subscribe((wait) => {
      if (!wait) triggerDebounce();
    });
  } else if (!waitForClient) {
    triggerDebounce();
  }
}

export function getDebouncedLoadAdFunction() {
  return debouncedLoadAstTags;
}
