import { useEffect, useMemo, useState } from 'react';

import { debugLog } from '@schibsted-nmp/advertising-shared';

import { $advItemIds } from '../atoms/index.js';

type Props = {
  itemId: string;
};

type IdTypes = {
  placementId: string;
  containerId: string;
  slotId: string;
};
type ReturnType = IdTypes & {
  adIndex: number;
};

export function useRecommendationIds({ itemId }: Props): ReturnType {
  const [adIndex, setAdIndex] = useState<number>(-1);

  useEffect(() => {
    const oldAdvtItemIds: string[] = $advItemIds.get();
    const newList: string[] = [...oldAdvtItemIds];
    if (!oldAdvtItemIds.includes(itemId)) {
      newList.push(itemId);
      $advItemIds.set(newList);
    }

    debugLog('AdvertisingRecircItemPageComponent', {
      itemId,
      oldAdvtItemIds,
      newList
    });
    setAdIndex(newList.indexOf(itemId));
  }, [itemId]);

  return useMemo<ReturnType>(() => {
    if (adIndex === -1)
      return {
        adIndex,
        placementId: '',
        containerId: '',
        slotId: ''
      };

    const placementId = `advt_recirc_middle_${adIndex + 1}`;
    const containerId = `${placementId}--container`;
    const slotId = `${placementId}--slot`;

    const returnData = {
      adIndex,
      placementId,
      containerId,
      slotId
    };
    debugLog('useRecommendationIds', returnData);
    return returnData;
  }, [adIndex]);
}
