import { deepMap } from '@podium/store';
import type { ClientAdPlacement } from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index.js';

export const $placementsMap = deepMap<Record<string, ClientAdPlacement>>(
  AtomChannels.Podlet,
  AtomTopics.PlacementsMap,
  {}
);

export function getPlacementList(): ClientAdPlacement[] {
  return Object.values($placementsMap.get()).filter(({ placementId }) =>
    Boolean(placementId)
  );
}

export function placementHasIntermingle({
  intermingle,
  placementId
}: ClientAdPlacement) {
  if (!intermingle) return false;

  const isRecircAd =
    placementId.includes('recirc') || placementId.includes('ad-stream');

  if (isRecircAd) return false;

  return Boolean(intermingle.list && intermingle.grid);
}
