import React, { useMemo } from 'react';

import { AdServer, type LayoutType } from '@schibsted-nmp/advertising-shared';

import type { PositionData } from './useSearchPositionInstruction.js';
import { AdvertisingWebComponent } from '../components/AdvertisingWebComponent.js';
import { AdvertisingAfsWebComponent } from '../components/AdvertisingAfsWebComponent.js';

export type UseAdComponents = {
  positionData: PositionData[];
  layoutType: LayoutType;
};

export function useAdComponents({ positionData, layoutType }: UseAdComponents) {
  return useMemo(
    () =>
      positionData.map(({ placementId, position, adServer }, i) => {
        if (adServer === AdServer.Afs) {
          return {
            element: <AdvertisingAfsWebComponent placementId={placementId} />,
            position
          };
        }
        return {
          element: (
            <AdvertisingWebComponent
              placementId={placementId}
              adIndex={i}
              key={`${placementId}${i}`}
              initialLayoutType={layoutType}
            />
          ),
          position
        };
      }),
    [positionData]
  );
}
