import React from 'react';

import { isValueType } from '@client/dev/components/utils/isValueType.js';

import { Static } from './Static.js';
import { Expandable } from './Expandable.js';

export function Entry({
  objectKey = null,
  data,
  depth = 0,
  expanded = null
}: Entry.Props) {
  if (typeof data !== 'object' || data === null) {
    return <Static objectKey={objectKey} value={data} />;
  }

  const isArray = Array.isArray(data);

  const brackets = React.useMemo(
    () => (isArray ? (['[', ']'] as const) : (['{', '}'] as const)),
    [isArray]
  );

  if (isArray) {
    if (data.length === 0) {
      return <Static objectKey={objectKey} brackets={brackets} noValue />;
    }

    if (data.length === 1) {
      const [value] = data as Array<unknown>;

      if (isValueType(value)) {
        return (
          <Static objectKey={objectKey} brackets={brackets} value={value} />
        );
      }
    }
  } else if (!Object.keys(data).length) {
    return <Static objectKey={objectKey} brackets={brackets} noValue />;
  }

  return (
    <Expandable
      objectKey={objectKey}
      depth={depth}
      expanded={expanded}
      brackets={brackets}
      data={data}
      showKeys={!isArray}
    />
  );
}

export namespace Entry {
  export type Props = {
    objectKey?: string | null;
    data: unknown;
    depth?: number;
    expanded?: boolean | null;
  };
}
