export namespace TcfApiMock {
  export const locatorName = '__tcfapiLocator';

  export namespace ConsentStrings.Consented {
    export const tcString =
      'CQJx3YAQJx3YAAGABCENBUFgAP_gAEPAAAZQJtJF5CfVDWFBoH51SZMAUIQF4dhgCkQhBBIBk8ABwDOCIBQWgCEwMAygJgICABAAKBABIAFkGACQBQAgKAARJBDAaECUgCBCJIAEgAEQUgZICAAICgEAIQAARPg4IhBQwBKswIJoAExQgwAGmSQZAoAAQBARAxMJAOp0QAIKkRACVCwwOglQAEgAAkAAAAAAAAAAAAAAAAAAAAAAAQQFgFAAKABAADIAGgATACaAKyAYIA5YCAoCYSAmAAgABYAFQAOAAeACIAIQAR0AygDLAHcAP2AkQCSgFRAMUAo8BeYDVwG5gP-CgAgAFAQFCABwAjgE0AJ2AdUBYgDIQGpigAIB1RgAEA6o6AoAAsACoAHAAiABiAGUANEAfsBHQElAKiAYoA-wCZAFHgLdAXmAywBq4D7gH_DwAQACgICjgBwACAAjgBQAEcAOQAdwBCACdgHVAWIAyEBqYDcwHLEIAwACwAxACOAHcAVEAxREACBAUgAGAAQAOQA6oCxAGpgOWJQCgAEAALAA4AEQAMQAjgCogGKAUeAvMmABAgKSACgBHAHcAdUBlgDlikBMABYAFQAOABEACkAGIAZQA0QB-gEdAJKAVEAxQB7QD7ALzAZYA8UB_xUAEAAoCApQASAEcARwA5AB3AEiALqAdUBqYDcy0AIAdwB9iwAMAZYDUwHLAAAA.YAAAAAAAAAAA';

    export const addtlConsent =
      '1~415.486.2328.540.149.239.108.108.584.3017.2510.1097.3244.2657.2949.1205.540.3128.2571.3190.2577.3234.576.2577.1969.3073.2567.3231.239.2608.1416.2891.2572.1558.2822.3070.2605.2316.1290.2337.2213.3100.3119.1364.2878.70.1046.2316.3128.2677.1969.89.2767.2572.3073.3190.2072.1215.2657.3043.2567.1716.550.2922.2821.89.2986';
  }

  export namespace ConsentStrings.NonConsented {
    export const tcString =
      'CQJx3YAQJx3YAAGABBENBUFgAAAAAAAAAAZQAAAAAAAA.YAAAAAAAAAAA';

    export const addtlConsent = '1~';
  }

  export type TcData = ReturnType<typeof TcData.from>;

  export namespace TcData {
    let currentValue: TcData | null = null;

    export function get() {
      return (currentValue ??= from('Consented'));
    }

    export function set(consentState: keyof typeof ConsentStrings) {
      currentValue = from(consentState);

      for (const [listenerId, callback] of Window.eventListeners) {
        try {
          callback(
            TcData.withListenerId(currentValue, Number.parseInt(listenerId, 10))
          );
        } catch {
          // do nothing
        }
      }
    }

    export function from(
      consentState: keyof typeof ConsentStrings = 'Consented'
    ) {
      const consents = Object.fromEntries(
        new Array<boolean>(2000).fill(consentState === 'Consented').entries()
      );

      const legitimateInterests = consents;
      const specialFeatureOptins = consents;
      const { tcString, addtlConsent } = ConsentStrings[consentState];

      return {
        addtlConsent,
        apiVersion: '2',
        cmpId: 6,
        cmpLoaded: true,
        cmpStatus: 'loaded',
        cmpVersion: 1,
        displayStatus: 'disabled',
        eventStatus: 'tcloaded',
        gdprApplies: true,
        gvlVersion: 81,
        isServiceSpecific: true,
        outOfBand: { allowedVendors: {}, disclosedVendors: {} },
        publisher: {
          consents,
          legitimateInterests,
          customPurpose: { consents, legitimateInterests },
          restrictions: {}
        },
        publisherCC: 'DK',
        purpose: { consents, legitimateInterests },
        purposeOneTreatment: false,
        specialFeatureOptins,
        tcfPolicyVersion: 5,
        tcString,
        useNonStandardTexts: false,
        vendor: { consents, legitimateInterests }
      } as const;
    }

    export function withListenerId(tcData: TcData, id?: number) {
      return {
        ...tcData,
        listenerId: id ?? Math.floor(Math.random() * 1e10)
      } as const;
    }
  }

  export type Window = {
    __tcfapi?(...args: ReadonlyArray<unknown>): void;
  };

  export namespace Window {
    /** Based on {@link https://raw.githubusercontent.com/InteractiveAdvertisingBureau/iabtcf-es/refs/heads/master/modules/stub/src/stub.js} */
    export function inject() {
      if (window.__tcfapi) return;

      const currentWindow = window;
      let frameLocator = currentWindow as typeof currentWindow | null;
      let cmpFrame: typeof frameLocator | null = null;

      while (frameLocator) {
        try {
          if (
            frameLocator.frames[locatorName as keyof typeof frameLocator.frames]
          ) {
            cmpFrame = frameLocator;
            break;
          }
        } catch {
          // do nothing
        }

        if (frameLocator === currentWindow.top) break;

        frameLocator = frameLocator.parent as typeof frameLocator.parent &
          typeof globalThis;
      }

      if (cmpFrame) return;

      Window.addFrame(currentWindow);

      const consentState =
        sessionStorage.getItem('ADVT_DEV_TOOL_consentState') === 'NonConsented'
          ? 'NonConsented'
          : 'Consented';

      TcData.set(consentState);

      currentWindow.__tcfapi = Window.__tcfapi;

      currentWindow.addEventListener(
        'message',
        Window.postMessageEventHandler,
        false
      );
    }

    export const eventListeners = new Map<string, (tcData: TcData) => void>();

    export function __tcfapi(
      ...[command, parameter, _callback, ...restArgs]: ReadonlyArray<unknown>
    ) {
      if (typeof _callback !== 'function') throw new Error('Invalid callback');

      const callback = _callback as (
        returnValue: unknown,
        success: boolean
      ) => unknown;

      switch (command) {
        case 'ping':
        case 'getTCData':
          callback(TcData.get(), true);
          break;

        case 'addEventListener': {
          const tcData = TcData.withListenerId(TcData.get());

          eventListeners.set(`${tcData.listenerId}`, () =>
            callback(tcData, true)
          );

          callback(tcData, true);

          break;
        }

        case 'removeEventListener': {
          const [listenerId] = restArgs;

          eventListeners.delete(String(listenerId));
          callback(null, true);

          break;
        }

        default:
          console.warn('Unhandled command for __tcfapi mock', {
            command,
            parameter,
            callback,
            restArgs
          });
      }
    }

    export function postMessageEventHandler(event: MessageEvent<unknown>) {
      if (!window.__tcfapi) return;

      const messageIsString = typeof event.data === 'string';
      let json: unknown = null;

      if (messageIsString) {
        try {
          json = JSON.parse(event.data);
        } catch {
          // do nothing
        }
      } else {
        json = event.data;
      }

      if (typeof json !== 'object') return;
      if (json === null) return;
      if (!('__tcfapiCall' in json)) return;
      if (typeof json.__tcfapiCall !== 'object') return;
      if (json.__tcfapiCall === null) return;

      const {
        command,
        version,
        callId,
        parameter
      }: Partial<Record<PropertyKey, unknown>> = json.__tcfapiCall;

      window.__tcfapi(
        command,
        version,
        (returnValue: unknown, success: true) => {
          const returnMsg = {
            __tcfapiReturn: { returnValue, success, callId }
          } as const;

          event.source?.postMessage(
            messageIsString ? JSON.stringify(returnMsg) : returnMsg,
            '*' as never // casting to any due to bad typings
          );
        },
        parameter
      );
    }

    export function addFrame(currentWindow: typeof window): boolean {
      const currentDocument = currentWindow.document;

      const otherCmpExists = Boolean(
        currentWindow.frames[locatorName as keyof typeof currentWindow.frames]
      );

      if (!otherCmpExists) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (currentDocument.body) {
          const iframe = currentDocument.createElement('iframe');

          iframe.style.cssText = 'display:none';
          iframe.name = locatorName;

          currentDocument.body.appendChild(iframe);
        } else {
          setTimeout(() => addFrame(currentWindow), 5);
        }
      }

      return !otherCmpExists;
    }
  }
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/consistent-type-definitions
  interface Window extends TcfApiMock.Window {}
}
